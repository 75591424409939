import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GetBusinessSectorDTO } from '../models/user-onboarding.models';
import { COMPANY_TYPES, Sector } from '../../shared/constants';
import { FeatureFlagService } from '../../shared/services/types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../shared/constants/feature-flag.constants';

@Injectable()
export class UserOnboardingService {
  private apiUrl = environment.onboardingApiPath;

  private businessSector$ = new BehaviorSubject<Sector>(null);

  private businessCompanyType = new BehaviorSubject<COMPANY_TYPES>(null);

  constructor(
    private http: HttpClient,
    private featureFlagService: FeatureFlagService,
  ) {}

  async fetchBusinessSector(): Promise<void> {
    const onboardingEnabled = await firstValueFrom(
      this.featureFlagService.isEnabled(
        FeatureFlagEnum.EnableFreeTrialUserOnboarding,
      ),
    );
    if (!onboardingEnabled) return;
    if (this.businessSector$.value !== null) return;
    try {
      const businessAnswers = await firstValueFrom(
        this.http.get<GetBusinessSectorDTO>(
          `${this.apiUrl}/answers/question-type`,
        ),
      );
      this.businessSector$.next(businessAnswers.SECTOR);
      this.businessCompanyType.next(businessAnswers.COMPANY_TYPE);
    } catch (error) {
      this.businessSector$.next(undefined);
      this.businessCompanyType.next(undefined);
      console.log(error);
    }
  }

  isBusinessSectorRetail(): boolean {
    return this.businessSector$.value === Sector.Retail;
  }

  getBusinessCompanyType(): COMPANY_TYPES {
    return this.businessCompanyType.value;
  }
}
