import Currency from 'currency.js';

export class CalculationService {
  private options = {
    precision: 8,
  };

  constructor(precision = 8) {
    this.options = {
      precision,
    };
  }

  add(a: number, b: number): number {
    return new Currency(a, this.options).add(b).value;
  }

  subtract(a: number, b: number): number {
    return new Currency(a, this.options).subtract(b).value;
  }

  divide(a: number, b: number): number {
    return new Currency(a, this.options).divide(b).value;
  }

  multiply(a: number, b: number): number {
    return new Currency(a, this.options).multiply(b).value;
  }

  roundToFixedDigits(value: number | string, decimals = 2): number {
    if (typeof value === 'string' && isNaN(Number(value))) {
      throw new Error('value must be a numeric string');
    }
    return new Currency(value, { precision: decimals }).value;
  }

  addWith2DecimalPrecision = (a: number, b: number) =>
    new Currency(a, { precision: 2 }).add(b).value;

  subtractWith2DecimalPrecision = (a: number, b: number) =>
    new Currency(a, { precision: 2 }).subtract(b).value;

  roundTo2Decimals = (a: number) => {
    const num = new Currency(Math.abs(a), { precision: 2 }).value;
    if (a < 0) {
      return -num;
    }
    return num;
  };
}

export const costCalculator = new CalculationService(8);
export const quantityCalculator = new CalculationService(3);
