import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Merchant } from '../../shared/model/Merchant';
import { SettingsActions } from '../settings.action-types';

export interface UsersState extends EntityState<Merchant> {
  areUsersLoaded: boolean;
}
export const usersFeatureKey = 'users';

export const UsersAdaptor: EntityAdapter<Merchant> =
  createEntityAdapter<Merchant>();

export const initialUsersState = {
  ...UsersAdaptor.getInitialState(),
  areUsersLoaded: false,
};

const reducer = createReducer(
  initialUsersState,
  on(SettingsActions.usersLoaded, (state, action) =>
    UsersAdaptor.setAll(action.users, {
      ...state,
      areUsersLoaded: true,
    }),
  ),
  on(SettingsActions.userUpdated, (state, action) =>
    UsersAdaptor.updateOne(action.update, state),
  ),
  on(SettingsActions.userAdded, (state, action) =>
    UsersAdaptor.addOne(action.savedUser, state),
  ),
  on(SettingsActions.userInError, (state) => ({
    ...state,
    areUsersLoaded: false,
  })),
);

export const { selectAll } = UsersAdaptor.getSelectors();

export function usersReducer(state: UsersState | undefined, action: Action) {
  return reducer(state, action);
}
