import { Component, Input } from '@angular/core';
import { TagTypes } from '../../../shared/constants/tag.constants';

@Component({
  selector: 'rw-tag-v2',
  templateUrl: './tag-v2.component.html',
  styleUrls: ['./tag-v2.component.scss'],
})
export class TagV2Component {
  @Input() type: TagTypes;

  @Input() text: string;

  @Input() size: string;
}
