import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { FeatureFlags } from '../enum/feature-flag.interface';
import { FeatureFlagService } from '../types/feature-flag.service.interface';
import { environment } from '../../../../environments/environment';

type FeatureFlagResponse = {
  key: string;
  value: any;
};

type GetAllFeatureFlagsResponse = {
  count: number;
  result: FeatureFlagResponse[];
};

@Injectable()
export class FeatureFlagMockService implements FeatureFlagService {
  private readonly flagValues: Record<string, any> = {};

  private isInitialized = false;

  private readonly APP_URL = '/api/feature-flag';

  private readonly IS_FF_LOADED_KEY = 'isFFLoaded';

  private readonly headers = {
    'x-api-key': environment.automationTestFFAPIKey ?? '1234',
  };

  constructor(private http: HttpClient) {
    try {
      // eslint-disable-next-line global-require
      this.flagValues = require('flag_values.json');
    } catch (err) {
      if (environment.mockLaunchDarkly) {
        throw err;
      }
    }
  }

  private loadAllFeatureFlags(): void {
    if (
      this.isInitialized ||
      (localStorage.getItem(this.IS_FF_LOADED_KEY) &&
        localStorage.getItem(this.IS_FF_LOADED_KEY) === 'true')
    ) {
      this.isInitialized = true;
      return;
    }
    this.http
      .get<GetAllFeatureFlagsResponse>(`${this.APP_URL}/get-all`, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          console.log('Fetched all feature flags:', response);
          response.result.forEach((flag) => {
            // Check if the key is already in localStorage
            if (!localStorage.getItem(flag.key)) {
              // If not, add it to localStorage
              localStorage.setItem(flag.key, JSON.stringify(flag.value));
              console.log(`Added to localStorage | ${flag.key}: ${flag.value}`);
            } else {
              console.log(
                `Skipped | ${flag.key} already exists in localStorage`,
              );
            }
          });
          localStorage.setItem(this.IS_FF_LOADED_KEY, 'true');
          this.isInitialized = true;
          return response.result;
        }),
        catchError((error) => {
          console.error('Error fetching all feature flags:', error);
          return throwError(error);
        }),
      )
      .subscribe(); // Ensure the request is executed
  }

  async isEnabledPromisified<T extends keyof FeatureFlags>(
    featureFlagName: T,
  ): Promise<FeatureFlags[T]> {
    return firstValueFrom(this.isEnabled(featureFlagName));
  }

  isEnabled<T extends keyof FeatureFlags>(
    featureFlagName: T,
    defaultValue?: any,
  ): Observable<FeatureFlags[T]> {
    if (environment.enableAutomationTestFF) {
      this.loadAllFeatureFlags();
      const localStorageValue = JSON.parse(
        localStorage.getItem(featureFlagName),
      );
      console.log(
        `From local storage | ${featureFlagName}:${localStorageValue}`,
      );
      if (localStorageValue !== null) {
        return of(localStorageValue);
      }
      return this.http
        .get<FeatureFlagResponse>(
          `${this.APP_URL}/get?key=${featureFlagName}`,
          {
            headers: this.headers,
          },
        )
        .pipe(
          map((response) => response.value ?? defaultValue),
          catchError((error) => {
            console.error(
              'Error fetching feature flag value from the endpoint:',
              error,
            );
            return throwError(error);
          }),
        );
    }
    return of(this.flagValues[featureFlagName] ?? defaultValue);
  }

  variation<T extends keyof FeatureFlags>(
    featureFlagName: T,
    defaultValue?: any,
  ): Observable<FeatureFlags[T]> {
    if (environment.enableAutomationTestFF) {
      this.loadAllFeatureFlags();
      const localStorageValues = JSON.parse(
        localStorage.getItem(featureFlagName),
      );
      console.log(
        `From local storage | ${featureFlagName}:${localStorageValues}`,
      );
      if (localStorageValues !== null) {
        return of(localStorageValues);
      }
      return this.http
        .get<FeatureFlagResponse>(
          `${this.APP_URL}/get?key=${featureFlagName}`,
          {
            headers: this.headers,
          },
        )
        .pipe(
          map((response) => response.value || defaultValue),
          catchError((error) => {
            console.error(
              'Error fetching feature flag value from the endpoint:',
              error,
            );
            return throwError(error);
          }),
        );
    }
    return of(this.flagValues[featureFlagName] ?? defaultValue);
  }
}
