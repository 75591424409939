<div class="auth-page">
  <div class="container" [ngClass]="lang === 'ar' ? 'rtl' : 'ltr'">
    <div class="auth-head">
      <div class="flex align-items-center justify-content-between py-5">
        <div>
          <a href="https://www.rewaatech.com/" target="_blank" class="rw__link" pendo-tracker="login-redirection-to-marketing-page">
            <img src="../../../../assets/images/{{lang === 'ar' ? 'logo-ar.png' : 'logo-en.png'}}" alt="" height="36">
          </a>
        </div>
        <div class="flex align-items-center gap-2">
          <rw-language-switcher-v2 [changeLang]="changeLang"></rw-language-switcher-v2>
        </div>
      </div>
    </div>
    <div class="auth-body">
      <div class="flex flex-column gap-4 py-5">
        <div>
          <label class="font-bold text-2xl mb-0">
            {{ 'auth.signInTitle' | translate }}
          </label>
        </div>
        <form
          [formGroup]="fGroup"
          #ngForm
          (ngSubmit)="login(fGroup.value)"
          class="flex flex-column gap-4"
        >
          <div class="flex flex-column gap-3">
            <!-- Login Input - Start -->
            <div>
              <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
                <i class="fa-regular fa-envelope"></i>
                <input pendo-tracker="li-v2"
                  formControlName="email"
                  pInputText
                  type="email"
                  id="email" 
                  placeholder="{{ 'Email' | translate }}"
                />
              </span>
              <ng-container *ngIf="email.touched && email.hasError('required')">
                <div class="flex align-items-center mt-1">
                  <i class="fa-solid fa-circle-exclamation p-error"></i>
                  <small class="p-error block mx-1">
                    {{ 'Email is required' | translate }}
                  </small>
                </div>
              </ng-container>
              <ng-container *ngIf="email.touched && email.hasError('email')">
                <div class="flex align-items-center mt-1 font-light">
                  <i class="fa-solid fa-circle-exclamation p-error"></i>
                  <small class="p-error block mx-1">
                    {{ 'Not valid email' | translate }}
                  </small>
                </div>
              </ng-container>
            </div>
            <!-- Login Input - End -->

            <!-- Passsword Input - Start -->
            <div>
              <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
                <i class="fa-regular fa-lock"></i>
                <p-password
                  class="auth-password-control"
                  [formControl]="password"
                  [toggleMask]="true"
                  [feedback]="false"
                  [showClear]="password.value"
                  required placeholder="{{ 'Password' | translate }}"
                  styleClass="w-full">
                </p-password>
              </span>
              <ng-container *ngIf="password.touched && password.invalid">
                <div class="flex align-items-center mt-1">
                  <i class="fa-solid fa-circle-exclamation p-error"></i>
                  <small class="p-error block mx-1">
                    {{ 'Password is required' | translate }}
                  </small>
                </div>
              </ng-container>
            </div>
            <!-- Password Input - End -->
          </div>

          <!-- Forgot Password - Start -->
          <div>
            <a pendo-tracker="la-v2" class="text-teal-60" [routerLink]="['/reset-password']">
              {{ 'auth.forgotPassword' | translate }}
            </a>
          </div>
          <!-- Forgot Password - End -->

          <!-- Submit - Start -->
          <button
            pendo-tracker="lb-v2"
            pButton pRipple
            type="submit"
            class="flex align-items-center justify-content-center w-full"
            [disabled]="isShowInternalSpinner|| fGroup.invalid"
          >
            <p-progressSpinner *ngIf="isShowInternalSpinner" [style]="{ width: '24px', height: '24px' }" 
              styleClass="flex rw__spinner rw__me-2" strokeWidth="4"></p-progressSpinner>
            <span class="text-base">
              {{ 'auth.login' | translate }}
            </span>
          </button>
          <!-- Submit - End -->
        </form>

        <!-- Already Account - Start -->
        <div class="text-center mt-2">
          <span class="text-base text-grey-50">
            {{'auth.newToRewaa' | translate}}
            <a [routerLink]="['/register']" class="rw__link" pendo-tracker="login-lb-1-v2">
              {{ 'auth.signUpPromptForNewUsers' | translate }}
            </a>
          </span>
        </div>
        <!-- Already Account - End -->
      </div>
    </div>
  </div>
</div>
