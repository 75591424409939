<ng-template #errorTemplate let-message="message" let-dirty="dirty" let-error="error">
    <li 
        class="flex align-items-center gap-2"
        [ngClass]="{
            'text-success': !error && dirty, 
            'text-danger': error && dirty,
            'text-grey-50': !dirty
        }"
    >
        <i 
            *ngIf="!error && dirty" 
            class="text-base fa-solid fa-circle-check">
        </i>
        <i 
            *ngIf="error && dirty" 
            class="text-base fa-solid fa-circle-exclamation">
        </i>
        <i 
            *ngIf="!dirty" 
            class="text-base fa-regular fa-circle">
        </i>
        <span 
        [ngClass]="{
            'text-grey-90': !error && dirty, 
            'text-danger': error && dirty,
            'text-grey-50': !dirty
        }">{{ 'auth.passwordValidation.password' + message | translate }}</span>
    </li>
</ng-template>

<div class="flex flex-column gap-4 pb-5">
    <div class="text-center">
        <img src="../../../../assets/icons/create-password.svg" width="160">
    </div>
    <div class="flex flex-column gap-2">
        <label class="font-bold text-2xl mb-0">
            {{ 'auth.createPasswordTitle' | translate }}
        </label>
        <ul class="wizard-progress">
            <li 
                *ngFor="let idx of [0, 1, 2];"
                [ngClass]="{
                'active': idx === 1,
                'done': idx < 1,
                }"
            ></li>
        </ul>
    </div>
    <form class="flex flex-column gap-3" [formGroup]="passwordForm" #ngForm (ngSubmit)="register()">
        <!-- Enter Password - Start -->
        <div class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full">
            <i class="fa-regular fa-lock"></i>
            <p-password 
                class="auth-password-control"
                [formControl]="password"
                placeholder="{{ 'auth.enterPassword' | translate }}"
                [toggleMask]="true"
                [feedback]="false"
                [showClear]="true"
                styleClass="w-full" required>
            </p-password>
        </div>
        <!-- Enter Password - End -->

        <!-- Password Pattern Validation - Start -->
        <ul class="flex flex-column text-sm p-0 m-0 gap-2">
            <ng-container
                [ngTemplateOutlet]="errorTemplate" 
                [ngTemplateOutletContext]="{ 
                    message: 'Length',
                    dirty: minLengthErrorHistory,
                    error: minLengthError,
                }">
            </ng-container>
            <ng-container
                [ngTemplateOutlet]="errorTemplate" 
                [ngTemplateOutletContext]="{ 
                    message: 'Case',
                    dirty: caseErrorHistory,
                    error: caseError,
                }">
            </ng-container>
            <ng-container
                [ngTemplateOutlet]="errorTemplate" 
                [ngTemplateOutletContext]="{ 
                    message: 'Number',
                    dirty: numberErrorHistory,
                    error: numberError,
                }">
            </ng-container>
            <ng-container
                [ngTemplateOutlet]="errorTemplate" 
                [ngTemplateOutletContext]="{ 
                    message: 'Symbol',
                    dirty: symbolErrorHistory,
                    error: symbolError,
                }">
            </ng-container>
        </ul>
        <!-- Password Pattern Validation - End -->

        <!-- Submit - Start -->
        <button
            pButton pRipple
            pendo-tracker="create_password_next_cta_button_click"
            type="submit"
            class="flex align-items-center justify-content-center w-full mt-2"
            [disabled]="isShowSpinner || isShowInternalSpinner || passwordForm.invalid">
            <p-progressSpinner *ngIf="isShowInternalSpinner" [style]="{ width: '24px', height: '24px' }" 
                styleClass="flex rw__spinner rw__me-2" strokeWidth="4"></p-progressSpinner>
            <span class="text-base mr-2">
                {{ 'Next' | translate }}
            </span>
            <!-- <i class="fa-regular fa-chevron-right m-0"></i> -->
        </button>
        <!-- Submit - End -->
    </form>
    
    <!-- Back - Start -->
    <button
        pButton pRipple
        pendo-tracker="create_password_edit_info_cta_button_click"
        class="flex align-items-center justify-content-center w-full p-button-text p-button-plain text-grey-90"
        (click)="editInfoEmit()"
    >
        <i 
            class="fa-regular rw__me-3"
            [ngClass]="{
                'fa-chevron-left': lang==='en',
                'fa-chevron-right': lang==='ar',
            }"
        ></i>
        <span>
            {{ 'auth.goBack' | translate }}
        </span>
    </button>
    <!-- Back - End -->
</div>