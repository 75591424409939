import { Observable } from 'rxjs';
import { FeatureFlags } from '../enum/feature-flag.interface';

export abstract class FeatureFlagService {
  abstract isEnabled<T extends keyof FeatureFlags>(
    featureFlagName: T,
    defaultValue?: any,
  ): Observable<FeatureFlags[T]>;

  abstract isEnabledPromisified<T extends keyof FeatureFlags>(
    featureFlagName: T,
  ): Promise<FeatureFlags[T]>;

  abstract variation<T extends keyof FeatureFlags>(
    featureFlagName: T,
    defaultValue?: any,
  ): Observable<FeatureFlags[T]>;
}
