import { CartCalculationService } from './cart-calculation.service';
import { v4 } from 'uuid';
import {
  Cart,
  Discount,
  ECard,
  LineItem,
  LineItemFindByParam,
  LineItemPrice,
  LineItemTax,
  TrackDetail,
} from './types';
import { InvoiceVariant } from '../invoice/types';
import { ProductEnums } from '@rewaa-team/types';
import { InvoiceTypes } from '..';
import { PromotionDetails } from '../promotions/types';

class ReturnCartService extends CartCalculationService {
  /**
   * @param cart The current cart
   * @param item The line item to add to cart
   * @param quantity The quantity of the item to add
   * @param trackNumbers An array of string of track numbers or ecards codes for adding serial products and ecards respectively.
   * @returns
   */
  public addReturnLineItem(
    cart: Cart,
    item: InvoiceTypes.InvoiceVariant,
    quantity = 1,
    trackNumbers: Record<string, number> = {},
  ): Cart {
    const existingLineItem = this.getLineItems(cart).find(
      (lineItem) => lineItem.id === item.lineItemId,
    );
    let newQuantity = quantity;
    if (existingLineItem) {
      newQuantity = quantity + existingLineItem.quantity;
      return this.updateLineItemQuantity(
        cart,
        {
          id: existingLineItem.id,
        },
        newQuantity,
        trackNumbers,
      );
    }

    const newLineItem = returnCartService.mapInvoiceVariantToLineItem(
      item,
      quantity,
    );

    if (newLineItem.trackType) {
      const packRate =
        newLineItem.type === ProductEnums.VariantType.Package
          ? newLineItem.packs[0]?.rate || 1
          : 1;
      newLineItem.trackDetails = this.getTrackDetails(
        newLineItem.trackDetails,
        newLineItem.trackType,
        trackNumbers,
      );
      newQuantity = this.getTrackQuantity(newLineItem.trackDetails, packRate);
    } else if (newLineItem.eCards.length) {
      newLineItem.eCards = this.updateECardQuantity(
        newLineItem.eCards,
        Object.keys(trackNumbers).filter(
          (code) => trackNumbers[code] && trackNumbers[code] > 0,
        ),
      );
      newQuantity = newLineItem.eCards.reduce(
        (acc, card) => acc + card.quantity,
        0,
      );
    }

    return this.addLineItem(cart, newLineItem, newQuantity);
  }

  private updateBatchQuantity(
    tracks: TrackDetail[],
    updates: Record<string, number>,
  ): TrackDetail[] {
    return tracks.map((track): TrackDetail => {
      const quantity = updates[track.trackNo] || 0;
      return {
        ...track,
        quantity,
      };
    });
  }

  private getTrackDetails(
    tracks: TrackDetail[],
    trackType: ProductEnums.TrackType,
    trackNumbers: Record<string, number>,
  ): TrackDetail[] {
    return trackType === ProductEnums.TrackTypeConstant.Serial
      ? this.updateSerialsQuantity(
          tracks,
          Object.keys(trackNumbers).filter(
            (trackNo) => trackNumbers[trackNo] && trackNumbers[trackNo] > 0,
          ),
        )
      : this.updateBatchQuantity(tracks, trackNumbers);
  }

  private updateECardQuantity(eCards: ECard[], codes: string[]): ECard[] {
    return eCards.map((card) => {
      const quantity = codes.includes(card.code) ? 1 : 0;
      return {
        ...card,
        quantity,
      };
    });
  }

  private updateSerialsQuantity(
    tracks: TrackDetail[],
    serials: string[],
  ): TrackDetail[] {
    return tracks.map((track): TrackDetail => {
      const quantity = serials.includes(track.trackNo) ? 1 : 0;
      return {
        ...track,
        quantity,
      };
    });
  }

  public removeReturnLineItem(
    cart: Cart,
    lineItemId: string,
    quantity: number,
  ): Cart {
    const lineItems = this.getLineItems(cart);
    const existingQuantity =
      lineItems.find((lineItem) => lineItem.id === lineItemId)?.quantity ||
      quantity;
    const remainingQuantity = existingQuantity - (quantity || existingQuantity);
    if (remainingQuantity === 0) {
      return this.removeLineItem(cart, { id: lineItemId });
    } else {
      return this.updateLineItemQuantity(
        cart,
        { id: lineItemId },
        remainingQuantity,
      );
    }
  }

  /**
   *
   * @param cart
   * @param findBy
   * @param quantity the quantity for batches and eCards is set according th
   * @returns cart
   */
  public updateLineItemQuantity(
    cart: Cart,
    findBy: LineItemFindByParam,
    quantity: number,
    trackNumbers: Record<string, number> = {},
  ): Cart {
    const lineItemIndex = this.getLineItemIndex(cart.lineItems, findBy);
    let newQuantity = quantity;
    const lineItem = cart.lineItems[lineItemIndex];
    const { trackType, packs, type } = lineItem;
    let { trackDetails, eCards } = lineItem;
    if (trackType) {
      const packRate =
        type === ProductEnums.VariantType.Package ? packs[0]?.rate || 1 : 1;
      trackDetails = this.getTrackDetails(
        trackDetails,
        trackType,
        trackNumbers,
      );
      newQuantity = this.getTrackQuantity(trackDetails, packRate);
    } else if (eCards.length) {
      eCards = this.updateECardQuantity(
        eCards,
        Object.keys(trackNumbers).filter(
          (code) => trackNumbers[code] && trackNumbers[code] > 0,
        ),
      );
      newQuantity = eCards.reduce((acc, card) => acc + card.quantity, 0);
    }
    if (newQuantity === 0) {
      return this.removeLineItem(cart, findBy);
    }
    return this.updateLineItem(cart, lineItemIndex, {
      quantity: newQuantity,
      trackDetails,
      eCards,
    });
  }

  public updateReturnLineItem(
    cart: Cart,
    lineItemId: string,
    quantity: number,
  ): Cart {
    const lineItems = this.getLineItems(cart);
    const existingLineItem = lineItems.find(
      (lineItem) => lineItem.id === lineItemId,
    );
    if (!existingLineItem) {
      return cart;
    }
    return this.updateLineItemQuantity(cart, { id: lineItemId }, quantity);
  }

  mapInvoiceVariantToLineItem(
    invoiceVariant: InvoiceVariant,
    qty: number,
  ): LineItem {
    const {
      name,
      sku,
      variantId,
      taxConfig,
      sellPrice,
      cost,
      quantity,
      meta,
      discounts,
      manageStockLevel,
      lineItemId,
    } = invoiceVariant;

    const priceTaxExclusive: LineItemPrice = {
      base: sellPrice,
      retail: sellPrice,
      wholesale: sellPrice,
      final: sellPrice,
      extrasTotal: 0,
    };

    const priceTaxInclusive: LineItemPrice = {
      base: sellPrice,
      retail: sellPrice,
      wholesale: sellPrice,
      final: sellPrice,
      extrasTotal: 0,
    };

    return {
      discounts: this.mapInvoiceVariantDiscountToDiscount(
        discounts as InvoiceTypes.InvoiceVariantDiscount[],
      ),
      imageUrl: '',
      promotionDetails: [],
      quantity: qty,
      subtotal: 0,
      subtotalWithTax: 0,
      total: 0,
      totalDiscount: 0,
      totalTax: 0,
      totalWithoutTax: 0,
      variantId,
      productId: meta?.productId as number,
      type: meta?.type as ProductEnums.VariantType,
      id: lineItemId || v4(),
      index: 0,
      name,
      productName: name,
      sku,
      tax: taxConfig as LineItemTax,
      priceTaxExclusive,
      priceTaxInclusive,
      cost,
      availableQuantity: quantity,
      productType: ProductEnums.ProductType.Simple,
      trackType: meta?.trackType,
      trackDetails:
        meta?.trackDetails?.map((track) => ({
          ...track,
          quantity: 0,
        })) || [],
      composites: meta?.composites || [],
      packs: meta?.packs || [],
      extras: meta?.extras || [],
      eCards:
        meta?.eCards?.map((eCard) => ({
          ...eCard,
          quantity: 0,
        })) || [],
      unit: meta?.unit || '',
      categoryIds: meta?.categoryIds || [],
      promotions: [],
      manageStockLevel,
    };
  }

  addPromotionDetails(
    cart: Cart,
    lineItemId: string,
    promotionDetails: PromotionDetails,
  ): Cart {
    const { lineItems } = cart;
    const lineItemIndex = lineItems.findIndex(
      (lineItem) => lineItem.id === lineItemId,
    );

    if (lineItemIndex < 0) {
      return cart;
    }

    const newLineItem = { ...lineItems[lineItemIndex] };
    const existingPromotionQuantity =
      newLineItem.promotionDetails[0]?.applicableQuantity || 0;
    promotionDetails.applicableQuantity += existingPromotionQuantity;
    newLineItem.promotionDetails = [promotionDetails];

    return this.calculateCart({
      ...cart,
      lineItems: [
        ...lineItems.slice(0, lineItemIndex),
        newLineItem,
        ...lineItems.slice(lineItemIndex + 1),
      ],
    });
  }

  public removePromotionDetails(
    cart: Cart,
    lineItemId: string,
    quantity: number,
  ): Cart {
    const { lineItems } = cart;
    const lineItemIndex = lineItems.findIndex(
      (lineItem) => lineItem.id === lineItemId,
    );

    if (lineItemIndex < 0) {
      return cart;
    }
    const newLineItem = { ...lineItems[lineItemIndex] };
    const existingPromotion = newLineItem.promotionDetails[0];
    if (!existingPromotion) {
      return cart;
    }
    const existingPromotionQuantity = existingPromotion.applicableQuantity || 0;
    const newPromotionQuantity = Math.max(
      existingPromotionQuantity - quantity,
      0,
    );
    if (newPromotionQuantity === 0) {
      newLineItem.promotionDetails = [];
    } else {
      newLineItem.promotionDetails = [
        {
          ...existingPromotion,
          applicableQuantity: newPromotionQuantity,
        },
      ];
    }

    return this.calculateCart({
      ...cart,
      lineItems: [
        ...lineItems.slice(0, lineItemIndex),
        newLineItem,
        ...lineItems.slice(lineItemIndex + 1),
      ],
    });
  }

  private mapInvoiceVariantDiscountToDiscount(
    discounts: InvoiceTypes.InvoiceVariantDiscount[] = [],
  ): Discount[] {
    return discounts.map((discount) => ({
      name: discount.name,
      rate: +discount.rate,
      amountType: discount.amountType,
      type: discount.type,
      unitAmount: +discount.unitAmount,
      total: +discount.total,
      totalWithTax: +discount.totalWithTax,
    }));
  }
}

export const returnCartService = new ReturnCartService();
