import { LineItemError, TrackDetail, VariantStockDetails } from '../cart/types';
import { costCalculator } from '@rewaa-team/rewaa-common';
import { ErrorCodesConstant } from '../cart/enums';

export const initializeStock = (
  quantity: number,
  availableQuantity: number,
): VariantStockDetails => ({ quantity, availableQuantity });

export const updateAvailableQuantityForTrackedProduct = (
  stock: VariantStockDetails,
  trackDetails: TrackDetail[] = [],
  packRate = 1,
): void => {
  stock.availableQuantity = 0;
  trackDetails?.forEach((track: TrackDetail) => {
    stock.availableQuantity = costCalculator.add(
      stock.availableQuantity,
      costCalculator.multiply(track.availableQuantity, packRate),
    );
  });
};

export const checkStock = (
  stock: VariantStockDetails,
  isManageStockLevel: boolean,
): LineItemError | null => {
  const quantity = stock?.quantity || 0;
  const availableQuantity = stock?.availableQuantity || 0;
  if (
    !stock ||
    !isManageStockLevel ||
    (availableQuantity && availableQuantity >= quantity)
  ) {
    return null;
  }
  return {
    [ErrorCodesConstant.InsufficientStock]: {
      quantity,
      availableQuantity,
    },
  };
};
