<p-columnFilter
  *ngIf="col"
  #ft
  [ngClass]="loading ? 'disabled' : ''"
  [field]="col.prop"
  [matchMode]="col.matchMode ? col.matchMode : defaultMatchModes[col.cellTemplate]"
  [display]="display"
  [showMatchModes]="false"
  [showOperator]="false"
  [showClearButton]="false"
  [showApplyButton]="false"
  [showAddButton]="false"
  [attr.pendo-tracker]="col.pendoTag"
  [filterIcon]="'fa-regular fa-calendar'"
>
  <ng-template
    pTemplate="filter"
    let-value
    let-filter="filterCallback"
  >
  <div *ngIf="
    col.cellTemplate === cellTemplates.Tag">
    <p-listbox [options]="col.filterValues" [(ngModel)]="tableFilters[col.prop]" optionLabel="label" optionValue="value"
      [listStyle]="{'max-height':'215px'}" [metaKeySelection]="false" [checkbox]="true">
      <ng-template let-option pTemplate="item">
        <div class="flex align-items-center justify-content-between w-full gap-2">
          <span>{{ col.disableValueTranslation ?
            (option.label)
            : (option.label | translate)
            }}</span>
          <span class="icon-check"><i class="fa-regular fa-check rw__fs-7"></i></span>
        </div>
      </ng-template>
    </p-listbox>
  </div>
    <div
      *ngIf="
      col.cellTemplate === cellTemplates.MultiSelect ||
      col.cellTemplate === cellTemplates.TaggedMultiSelect ||
      col.cellTemplate === cellTemplates.DropDown
    "
    >
      <p-listbox
        *ngIf="!isDialogFilter"
        [options]="col.filterValues"
        [(ngModel)]="tableFilters[col.prop]"
        optionLabel="name"
        [multiple]="
        (col.cellTemplate === cellTemplates.MultiSelect || col.cellTemplate === cellTemplates.TaggedMultiSelect)
      "
        optionValue="value"
        [listStyle]="{'max-height':'215px'}"
        [metaKeySelection]="false"
        [checkbox]="true"
        [filter]="true"
        [filterPlaceHolder]="'primeng.search' | translate"

      >
        <ng-template
          let-option
          pTemplate="item"
        >
          <div class="flex align-items-center justify-content-between w-full gap-2">
      <span>{{ col.disableValueTranslation ?
        (option.name)
        : (option.name | translate)
        }}</span>
            <span
              class="icon-check"
              *ngIf="(col.cellTemplate !== cellTemplates.MultiSelect &&
                      col.cellTemplate !== cellTemplates.TaggedMultiSelect)"
            >
              <i class="fa-regular fa-check rw__fs-7"></i>
            </span>
          </div>
        </ng-template>
      </p-listbox>
      <p-listbox
        *ngIf="isDialogFilter"
        [options]="visibleFilterValues"
        [(ngModel)]="tableFilters[col.prop]"
        optionLabel="name"
        [multiple]="(col.cellTemplate === cellTemplates.MultiSelect || col.cellTemplate === cellTemplates.TaggedMultiSelect)"
        optionValue="value"
        [listStyle]="{ 'max-height': '215px' }"
        [metaKeySelection]="false"
        [checkbox]="true"
        (onChange)="onListboxChange($event, col.prop)"
        [styleClass]="(col.cellTemplate === cellTemplates.MultiSelect || col.cellTemplate === cellTemplates.TaggedMultiSelect) ? 'table-dialog-filter-list' : 'table-filter-list'"
        [filter]="false"
      >
        <ng-template pTemplate="header">
          <div class="flex gap-2 search-input">
            <i class="fa-regular fa-magnifying-glass"></i
              >
            <input
              type="text"
              class="p-inputtext p-component"
              [placeholder]="'primeng.search' | translate"
              (input)="onSearchInput($event)"
            />
          </div>
        </ng-template>

        <ng-template
          let-option
          pTemplate="item"
        >
          <div
            class="flex align-items-center justify-content-between w-full gap-2"
          >
            <span>{{
              col.disableValueTranslation
                ? option.name
                : (option.name | translate)
            }}</span>
            <span
              class="icon-check"
              *ngIf="(col.cellTemplate !== cellTemplates.MultiSelect && col.cellTemplate !== cellTemplates.TaggedMultiSelect)"
              ><i class="fa-regular fa-check rw__fs-7"></i
            ></span>
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <div
            class="show-more rw__px-5 cursor-pointer text-primary rw__h6 fw-400"
            (click)="toggleListBoxItems()"
            *ngIf="
              !searchFilterValue && col.filterValues && col.filterValues.length > 5"
            >
            <ng-container *ngIf="isListExpanded; else showMoreItems">
              <span>
                {{ 'primeng.less' | translate }}
                <i class="rw__ms-1 fa-regular fa-chevrons-up"></i>
              </span>
            </ng-container>
            <ng-template #showMoreItems>
              <span>
                +{{ remainingListboxCount }} {{ 'primeng.more' | translate }}
                <i class="rw__ms-1 fa-regular fa-chevrons-down"></i>
              </span>
            </ng-template>
          </div>
        </ng-template>
      </p-listbox>
    </div>
    <div *ngIf="col.cellTemplate === cellTemplates.Text">
      <div class="flex rw__p-5">
        <input
          [attr.pendo-tracker]="col.pendoTag"
          type="text"
          pInputText
          [(ngModel)]="tableFilters[col.prop]"
        />
      </div>
    </div>
    <div *ngIf="col.cellTemplate === cellTemplates.Numeric">
      <div class="flex flex-column rw__p-5 rw__input">
        <p-dropdown
          appendTo="body"
          [options]="['=', '>', '>=', '<', '<=']"
          [(ngModel)]="col.operator"
          styleClass="rw__mb-3"
        ></p-dropdown>
        <input
          [attr.pendo-tracker]="col.pendoTag"
          type="number"
          pInputText
          [(ngModel)]="tableFilters[col.prop]"
        />
      </div>
    </div>

    <div *ngIf="col.cellTemplate === cellTemplates.NumericV2">
      <div class="flex flex-column rw__p-5 rw__input">
        <p-dropdown
          appendTo="body"
          [options]="operatorNames"
          (onChange)="onNumberFilterDropdownChange($event.value)"
          styleClass="rw__mb-3"
        ></p-dropdown>
        <input
          *ngIf="col.operator !== ComparisonOperatorsV2.Between"
          [attr.pendo-tracker]="col.pendoTag"
          type="number"
          pInputText
          [(ngModel)]="tableFilters[col.prop]"
        />
        <div class="flex flex-row between-inputs-container" *ngIf="col.operator === ComparisonOperatorsV2.Between">
          <input
            class="between-text"
            [attr.pendo-tracker]="col.pendoTag"
            type="number"
            pInputText
            [(ngModel)]="betweenOperatorFromValue"
          />
          <input
            class="between-text"
            [attr.pendo-tracker]="col.pendoTag"
            type="number"
            pInputText
            [(ngModel)]="betweenOperatorToValue"
          />
        </div>
      </div>
    </div>

    <div
      *ngIf="col.cellTemplate === cellTemplates.Boolean || col.cellTemplate === cellTemplates.BooleanNegate"
      [ngClass]="isDialogFilter ? 'rw__p-3' : 'rw__py-3'"
    >
      <p-listbox [(ngModel)]="tableFilters[col.prop]" [options]="booleanOptions">
        <ng-template let-option pTemplate="item">
          <p-listItem>
            <div class="flex align-items-center justify-content-between w-full gap-2">
              <span>{{ option.label }}</span>
              <span class="icon-check"><i class="fa-regular fa-check rw__fs-7"></i></span>
            </div>
          </p-listItem>
        </ng-template>
      </p-listbox>
    </div>

    <div *ngIf="col.cellTemplate === cellTemplates.Date || col.cellTemplate === cellTemplates.DateTime">
      <div class="p-date-range">
        <div class="p-date-range-list">
          <p-listbox
            [options]="dateFilterOpts"
            optionLabel="name"
            optionValue="value"
            [(ngModel)]="dateRanges[col.prop]"
            (onChange)="onDateFilterChange($event, col)"
          >
            <ng-template let-option pTemplate="item">
              <div class="flex align-items-center justify-content-between w-full gap-2">
                <span>{{ option.name }}</span>
                <span class="icon-check"><i class="fa-regular fa-check rw__fs-7"></i></span>
              </div>
            </ng-template>
          </p-listbox>
          <div class="rw__mt-5 flex flex-row gap-3">
          <span
            class="rw__input rw__input-float rw__input-float-md p-float-label minw-0"
          >
          <p-calendar
            inputId="specific-range-input-from"
            id="specific-range-input-from"
            lass="mt-5"
            [showIcon]="true"
            [showTime]="col.cellTemplate === cellTemplates.DateTime"
            icon="fa-regular fa-calendar"
            [placeholder]="'Choose Range' | translate"
            selectionMode="single"
            [appendTo]="'body'"
            (onSelect)="onSpecificRangeSelected($event, col, 'from')"
            [(ngModel)]="specificDateFrom"
          >
          </p-calendar>
          <label for="specific-range-input-from">
            {{ 'from' | translate }}
          </label>
        </span>
            <span
              class="rw__input rw__input-float rw__input-float-md p-float-label minw-0"
            >
          <p-calendar
            inputId="specific-range-input-to"
            id="specific-range-input-to"
            lass="mt-5"
            [showIcon]="true"
            [showTime]="col.cellTemplate === cellTemplates.DateTime"
            icon="fa-regular fa-calendar"
            [placeholder]="'Choose Range' | translate"
            selectionMode="single"
            [appendTo]="'body'"
            (onSelect)="onSpecificRangeSelected($event, col, 'to')"
            [(ngModel)]="specificDateTo"
          >
          </p-calendar>
          <label for="specific-range-input-to">
            {{ 'to' | translate }}
          </label>
        </span>
          </div>
        </div>
        <div class="d-flex justify-content-between p-date-range-type">
          <div class="d-flex align-items-center">
            <p-radioButton
              name="datetype"
              [(ngModel)]="col.dateType"
              [value]="dateTypes.Range"
              (ngModelChange)="onAllDayChange()"
              label="{{ 'allDay' | translate }}"
              class="rw__custom-radio-button"
            ></p-radioButton>
          </div>
          <div class="d-flex align-items-center">
            <p-radioButton
              name="datetype"
              [(ngModel)]="col.dateType"
              [value]="dateTypes.Specific"
              label="{{ 'Specific Time' | translate }}"
              class="rw__custom-radio-button"
            ></p-radioButton>
          </div>
        </div>
        <div class="p-date-range-calendar rw__mt-2" *ngIf="col.dateType === dateTypes.Specific">
          <ng-container >
            <rw-table-time-selector [selectedCustomDateFilter]="selectedCustomDateFilter" (timeRangeChanged)="onDateTimeFilterChange(col, $event)"></rw-table-time-selector>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="col.cellTemplate === cellTemplates.AutoComplete" class="rw__p-3 custom-autocomplete-input">
      <p-autoComplete
        [appendTo]="display === 'row' ? 'body' : ''"
        [multiple]="true"
        [ngModel]="autoCompleteSearches[col.prop]"
        [field]="autoCompleteField"
        [suggestions]="col.filterValues"
        (onSelect)="autoCompleteSelected(col, $event)"
        (onUnselect)="autoCompleteUnSelected(col, $event)"
        (completeMethod)="updateSearchValue(col, $event.query)"
        (onKeyUp)="autoCompleteKeyUp(col, $event)"
      ></p-autoComplete>
    </div>
    <div class="flex justify-content-end align-items-center rw__p-5 border-top-1" *ngIf="showButtons">
      <button
        [attr.pendo-tracker]="col.pendoTag"
        (click)="clearFilter(col, ft)"
        type="button"
        pButton
        pRipple
        class="p-button-plain p-button-text rw__button rw__button-xs w-auto rw__me-4"
        label="{{'Cancel' | translate}}"
      >
      </button>
      <button
        [disabled]="isSpecificRangeSelected && (!specificDateFrom || !specificDateTo)"
        [attr.pendo-tracker]="col.pendoTag"
        (click)="applyFilter(col, filter, ft)"
        type="button"
        pButton
        pRipple
        class="p-button-primary rw__button rw__button-xs w-auto"
        label="{{'Apply' | translate}}"
      >
      </button>
    </div>
  </ng-template>
</p-columnFilter>
