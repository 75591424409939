/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize, take } from 'rxjs/operators';
import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from 'src/app/shared/decorators/auto-unsubscribe';
import { firstValueFrom } from 'rxjs';
import { DropdownFilterOptions } from 'primeng/dropdown';
import { AuthUser, CreateLeadDTO } from '../../../auth.models';
import { AuthService } from '../../../services/auth.service';
import { LocalStorageKey } from '../../../../shared/constants';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { LocationService } from '../../../../core/services/location.service';
import { CouponCodeStatus } from '../../../../shared/model/enum/enums';
import { CustomToastService } from '../../../../shared/services/custom-toast.service';

@Component({
  selector: 'rw-sign-up',
  templateUrl: './sign-up.component.html',
  encapsulation: ViewEncapsulation.None,
})
@AutoUnsubscribe
export class SignUpComponent implements OnInit, OnDestroy, AutoUnsubscribeI {
  subscriptionRefs;

  @Input() signUpForm: UntypedFormGroup;

  @Output() completion = new EventEmitter();

  user: AuthUser;

  isShowSpinner = false;

  isShowInternalSpinner = false;

  changeLang: string;

  lang: string;

  referralCode = '';

  invalidLicense = null;

  registerData;

  openLicenseKeyDialogVisible = false;

  countries: any[] = [];

  selectedCountry: any;

  filterValue = '';

  showLicenseKey = false;

  termsWarning = false;

  userAlreadyExists = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private locationService: LocationService,
    private toastService: CustomToastService,
  ) {
    translate.addLangs(['ar', 'en']);
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(this.lang);
    this.registerData = this.router.getCurrentNavigation()?.extras?.state;
    if (!this.lang) {
      translate.setDefaultLang('ar');
      this.localStorageService.setItem<string>(LocalStorageKey.Language, 'ar');
      this.changeLang = 'en';
    } else {
      translate.setDefaultLang(this.lang);
      this.changeLang = this.lang === 'ar' ? 'en' : 'ar';
    }

    this.isShowSpinner = true;
    firstValueFrom(this.locationService.getCountries())
      .then((countries) => {
        this.countries = countries
          .map((country) => {
            const localeName =
              this.lang === 'ar'
                ? this.translate.instant(`country.${country.countryKey}`)
                : country.name;
            const countryWithLocale = { ...country, localeName };
            if (
              countryWithLocale.countryKey === 'SA' &&
              !this.selectedCountry
            ) {
              this.selectedCountry = countryWithLocale;
            }
            return countryWithLocale;
          })
          .sort((a, b) => a.localeName.localeCompare(b.localeName));
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.info(error);
      })
      .finally(() => {
        this.isShowSpinner = false;
      });

    const referralCode = this.localStorageService.getItem<string>(
      LocalStorageKey.ReferralCode,
    );
    if (referralCode && !this.referralCode) {
      this.referralCode = referralCode;
    }
    if (this.referralCode) {
      this.signUpForm.get('referralCode').setValue(this.referralCode);
    }
  }

  // eslint-disable-next-line class-methods-use-this, @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}

  get firstName(): AbstractControl | null {
    return this.signUpForm.get('firstName');
  }

  get lastName(): AbstractControl | null {
    return this.signUpForm.get('lastName');
  }

  get email(): AbstractControl | null {
    return this.signUpForm.get('email');
  }

  get companyName(): AbstractControl | null {
    return this.signUpForm.get('companyName');
  }

  get phone(): AbstractControl | null {
    return this.signUpForm.get('phone');
  }

  get licenseKey(): AbstractControl | null {
    return this.signUpForm.get('licenseKey');
  }

  get utmParams(): AbstractControl | null {
    return this.signUpForm.get('utmParams');
  }

  get isTermsAndPrivacyAccepted(): AbstractControl | null {
    return this.signUpForm.get('isTermsAndPrivacyAccepted');
  }

  ngOnInit(): void {
    this.subscriptionRefs = this.activatedRoute.queryParams.subscribe(
      (payload) => {
        // fetch license key for user scanning the STC QR code
        const licenseKey = payload.license;
        if (licenseKey) {
          this.licenseKey.setValue(licenseKey);
          this.validateLicense();
          this.handleShowLicenseKey();
        }
        if (this.areUtmParamsPresent(payload)) {
          const utmParams: string = this.getUtmParamsString(payload);
          this.utmParams.setValue(utmParams);
        }
        if (payload.ref) {
          this.referralCode = payload.ref;
        }
      },
    );

    this.isTermsAndPrivacyAccepted.valueChanges.subscribe((value) => {
      if (value) this.termsWarning = false;
    });

    this.companyName.valueChanges.subscribe(() => {
      this.companyName.markAsUntouched();
    });

    this.email.valueChanges.subscribe((event) => {
      this.userAlreadyExists = false;
      this.email.markAsUntouched();
      this.email.setValue(event?.toLowerCase(), { emitEvent: false });
    });

    this.phone.valueChanges.subscribe(() => {
      this.phone.markAsUntouched();
    });

    this.phone.setValidators([
      Validators.required,
      this.getPhoneNumberValidator(),
    ]);
  }

  getTermsAndConditionsHRef(): string {
    if (this.lang === 'ar')
      return 'https://www.rewaatech.com/%d8%a7%d9%84%d8%b4%d8%b1%d9%88%d8%b7-%d9%88-%d8%a7%d9%84%d8%a3%d8%ad%d9%83%d8%a7%d9%85/';
    return 'https://www.rewaatech.com/en/terms-conditions/';
  }

  getPrivacyPolicyHRef(): string {
    if (this.lang === 'ar')
      return 'https://www.rewaatech.com/%d8%b3%d9%8a%d8%a7%d8%b3%d8%a9-%d8%a7%d9%84%d8%ae%d8%b5%d9%88%d8%b5%d9%8a%d8%a9/ ';
    return 'https://www.rewaatech.com/en/privacy-policy/';
  }

  areUtmParamsPresent(payload: Params): boolean {
    return (
      payload.utm_pl ||
      payload.utm_me ||
      payload.utm_ch ||
      payload.utm_pu ||
      payload.utm_ca ||
      payload.utm_ag ||
      payload.utm_co ||
      payload.utm_keyword
    );
  }

  getUtmParamsString(payload: Params): string {
    const {
      utm_pl,
      utm_me,
      utm_ch,
      utm_pu,
      utm_ca,
      utm_ag,
      utm_co,
      utm_keyword,
    } = payload;
    const utmParams: { [key: string]: string } = {};
    if (utm_pl) utmParams.utmPlacement = utm_pl;
    if (utm_me) utmParams.utmMedium = utm_me;
    if (utm_ch) utmParams.utmChannel = utm_ch;
    if (utm_pu) utmParams.utmPublisher = utm_pu;
    if (utm_ca) utmParams.utmCampaign = utm_ca;
    if (utm_ag) utmParams.utmAdGroup = utm_ag;
    if (utm_co) utmParams.utmContent = utm_co;
    if (utm_keyword) utmParams.utmKeyword = utm_keyword;

    return Object.keys(utmParams).length === 0 ? '' : JSON.stringify(utmParams);
  }

  validateLicense(): void {
    this.isShowSpinner = true;
    firstValueFrom(this.authService.validateLicenseKey(this.licenseKey.value))
      .then((res) => {
        this.isShowSpinner = false;
        if (!res.coupon || res.coupon.status !== CouponCodeStatus.NotRedeemed) {
          this.invalidLicense = this.licenseKey.value;
          this.licenseKey.markAsTouched();
          this.validateLicenseKeyInput();
          return;
        }
        this.invalidLicense = null;
      })
      .catch(() => {
        this.isShowSpinner = false;
        this.invalidLicense = this.licenseKey.value;
        this.licenseKey.markAsTouched();
        this.validateLicenseKeyInput();
      });
  }

  onLicenseKeyComplete(): void {
    if (this.licenseKey.value !== this.invalidLicense) {
      this.validateLicense();
    } else if (this.invalidLicense)
      this.licenseKey.setErrors({ notValid: true });
  }

  validateLicenseKeyInput(): void {
    if (
      this.licenseKey.value.length === 20 &&
      this.licenseKey.value === this.invalidLicense
    ) {
      setTimeout(() => this.licenseKey.setErrors({ notValid: true }));
    }
  }

  getPhoneNumberValidator(): ValidatorFn {
    return (control: FormControl): null | Record<string, boolean> => {
      const inputValue = control.value;

      if (this.selectedCountry?.countryKey !== 'SA') {
        return /^[0-9]{5,13}$/.test(inputValue)
          ? null
          : { otherThanSaudiPattern: true };
      }

      if (!inputValue.startsWith('05') && !inputValue.startsWith('5')) {
        return { saudiZeroOrZeroFive: true };
      }

      const saudiPattern = inputValue.startsWith('0')
        ? /^05[0-9]{8}$/
        : /^5[0-9]{8}$/;

      const saudiError = inputValue.startsWith('0')
        ? { zeroSaudiPattern: true }
        : { saudiPattern: true };

      return saudiPattern.test(inputValue) ? null : saudiError;
    };
  }

  myResetFunction(options: DropdownFilterOptions): void {
    options.reset();
    this.filterValue = '';
  }

  onCountrySelect(): void {
    this.phone.setValue('');
  }

  clearLicenseKey(): void {
    this.licenseKey.setValue('');
  }

  private get leadInfo(): CreateLeadDTO {
    return {
      email: this.email.value,
      companyName: this.companyName.value,
      phone: this.selectedCountry.code + this.phone.value,
      utmParams: this.utmParams.value,
      isReferral: !!this.referralCode,
    };
  }

  submitSignUp(): void {
    this.signUpForm.markAllAsTouched();
    if (!this.isTermsAndPrivacyAccepted.value || this.signUpForm.invalid) {
      this.termsWarning = !this.isTermsAndPrivacyAccepted.value;
      return;
    }
    this.isShowInternalSpinner = true;
    this.authService
      .createLead(this.leadInfo)
      .pipe(
        take(1),
        finalize(() => {
          this.isShowInternalSpinner = false;
        }),
      )
      .subscribe({
        next: () => {
          this.signUpForm.get('utmParams').setValue(this.utmParams.value);
          this.signUpForm.get('referralCode').setValue(this.referralCode);
          this.signUpForm.get('selectedCountry').setValue(this.selectedCountry);
          this.toastService.success(
            this.translate.instant('auth.signUpSuccess'),
          );
          this.completion.emit();
        },
        error: (err) => {
          if (err.error.name === 'DUPLICATE_COGNITO_ENTRY') {
            this.userAlreadyExists = true;
          }
          // eslint-disable-next-line no-console
          console.error(err);
        },
      });
  }

  openLicenseKeyDialog(): void {
    this.openLicenseKeyDialogVisible = true;
  }

  closeLicenseKeyDialog(): void {
    this.openLicenseKeyDialogVisible = false;
  }

  handleShowLicenseKey(): void {
    this.showLicenseKey = true;
  }
}
