import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductType } from '@rewaa-team/types/src/inventory/product/enums';
import { LANGUAGES as Language } from '../constants';
import { environment } from '../../../environments/environment';

export enum ImportPhase {
  InsertionPhase = 'insertion',
  ValidationPhase = 'validation',
}

export enum ImportStatus {
  TemplateValidationStarted = 'templateValidationStarted',
  TemplateValidationCompleted = 'templateValidationCompleted',
  TemplateValidationFailed = 'templateValidationFailed',
  DataValidationStarted = 'dataValidationStarted',
  DataValidationCompleted = 'dataValidationCompleted',
  DataValidationFailed = 'dataValidationFailed',
  InsertionStarted = 'insertionStarted',
  InsertionCompleted = 'insertionCompleted',
  InsertionFailed = 'insertionFailed',
}

interface FileImportRequest {
  lang: Language;
  file: File;
  metadata?: {
    [x: string]: any;
  };
}

interface FileImportResponse {
  data: {
    message: string;
    RequestId: string;
  };
}

interface ImportProgressResponse {
  data: {
    message: string;
    data: {
      row_count: number;
      processed_count: number;
      validated_count: number;
      inserted_count: number;
      status: ImportStatus;
    };
  };
}

interface ImportProgressRequest {
  requestId: string;
  phase: ImportPhase;
}

interface InitiateImportResponse {
  data: {
    message: string;
  };
}

interface Enum {
  Name: string;
  Value: string;
}

interface Column {
  Name: string;
  Type: string;
  Key: string;
  Required: boolean;
  EnumList?: Enum[];
}

interface GetColumnsResponse {
  data: {
    message: string;
    columns: Column[];
  };
}

interface GetErrorReportResponse {
  data: {
    message: string;
    url: string;
  };
}

@Injectable()
export class ImexService {
  apiUrl = environment.imexUrl;

  constructor(private http: HttpClient) {}

  importFile(importReuest: FileImportRequest): Observable<FileImportResponse> {
    const formData = new FormData();
    formData.append('lang', importReuest.lang);
    formData.append('file', importReuest.file);
    if (importReuest.metadata) {
      formData.append('metadata', JSON.stringify(importReuest.metadata));
    }
    return this.http.post<FileImportResponse>(
      `${this.apiUrl}/import`,
      formData,
    );
  }

  getImportProgress(
    importProgressRequest: ImportProgressRequest,
  ): Observable<ImportProgressResponse> {
    const httpParams = new HttpParams()
      .append('requestId', importProgressRequest.requestId)
      .append('phase', importProgressRequest.phase);
    return this.http.get<ImportProgressResponse>(
      `${this.apiUrl}/import/progress`,
      {
        params: httpParams,
      },
    );
  }

  initiateInsert(requestId: string): Observable<InitiateImportResponse> {
    return this.http.post<InitiateImportResponse>(
      `${this.apiUrl}/import/insertion`,
      {
        requestId,
      },
    );
  }

  getColumns(
    language: Language,
    productType: ProductType,
    isLightVersion: boolean,
  ): Observable<GetColumnsResponse> {
    const params = new HttpParams()
      .append('lang', language)
      .append('productType', productType)
      .append('isLightVersion', isLightVersion);
    return this.http.get<GetColumnsResponse>(`${this.apiUrl}/import/columns`, {
      params,
    });
  }

  getErrorReport(requestId: string): Observable<GetErrorReportResponse> {
    const params = new HttpParams().append('requestId', requestId);
    return this.http.get<GetErrorReportResponse>(
      `${this.apiUrl}/import/error-report`,
      { params },
    );
  }
}
