/* eslint-disable max-classes-per-file */
import { RewaaAccountSubscription } from '../users-settings/models/RewaaAccountSubscription';
import { Location } from '../shared/model/Location';
import { Subscription } from './services/merchant.service';

export class AuthUser {
  public name?: string;

  constructor(
    public id: number,
    public email: string,
    public password: string,
    public companyName: string,
    public mobile: string,
    public phone: string,
    public website: string,
    public companyType: string,
    public schemaName: string,
    public status: string,
    public permissions: Permission[],
    public isActive: boolean,
    public firebaseToken: string,
    public intercomUserHash: string,
    public vatNumber?: string,
    public createdAt?: Date,
    public trialDays?: number,
    public remainingDays?: number,
    public locations?: Location[],
    public subscriptionDeletedAt?: Date,
    public isNotified?: boolean,
    public SystemRole?: string,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public RewaaAccountSubscription?: RewaaAccountSubscription,
    public rewaaAccountSubscriptionId?: number,
    public metabaseCollectionId?: number,
    public companyAddress?: string,
    public isExpired?: boolean,
    public daysLeftToDisableAccount?: number,
    public businessSize?: string,
    public businessSizeLastUpdatedOn?: Date,
    public isReseller?: boolean,
    public commercialRegisterNumber?: string,
    public defaultBranchName?: string,
    public district?: string,
    public city?: string,
    public country?: string,
    public streetName?: string,
    public industry?: string,
    public onboardingStatus?: string,
    public nearpayClientId?: string,
    public nearpayMerchantId?: string,
    public logoUrl?: string,
    public hrModuleSubscription?: Subscription,
  ) {}
}

export class SignupConfirmation {
  constructor(
    public email: string,
    public code: string,
  ) {}
}

export class MerchantUser {
  constructor(
    public id: number,
    public email: string,
    public companyName: string,
    public schemaName: string,
    public isActive: boolean,
    public status: string,
    public firebaseToken: string,
  ) {}
}

export class LoginDetails {
  constructor(
    public email: string,
    public password: string,
  ) {}
}

export class Permission {
  constructor(
    public name: string,
    public eventPath: string,
    public id: number,
    public selected: boolean,
  ) {}
}
export class ReferralLink {
  constructor(
    public link: string,
    public time: number,
  ) {}
}
export class ResetPasswordEmailStep {
  constructor(public email: string) {}
}

export class ResetPasswordNewPasswordStep {
  constructor(
    public password: string,
    public verificationCode: string,
  ) {}
}

export interface CreateLeadDTO {
  email: string;
  companyName: string;
  phone: string;
  utmParams: string;
  isReferral: boolean;
}

export interface UpdateCognitoNumberDTO {
  email: string;
  phoneNumber: string;
}
