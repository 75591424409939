import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  selectAll,
  posSettingsFeatureKey,
  PosSettingsState,
} from '../reducers/settings.reducer';

export const selectPosSettingsState = createFeatureSelector<PosSettingsState>(
  posSettingsFeatureKey,
);

export const posSettingsLoaded = createSelector(
  selectPosSettingsState,
  (state) => state && state.posSettingsLoaded,
);

export const selectAllPosSettings = createSelector(
  selectPosSettingsState,
  selectAll,
);
