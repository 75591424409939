import { Pipe, PipeTransform } from '@angular/core';
import { commonCalculationService } from '@rewaa-team/pos-sdk';

@Pipe({
  name: 'amountFormat',
})
export class AmountFormatPipe implements PipeTransform {
  transform(value?: number): string {
    if (value === null || value === undefined) {
      return '';
    }

    const roundedValue = commonCalculationService.roundTo2Decimals(value);
    if (roundedValue % 1 === 0) {
      return this.formatInteger(roundedValue);
    }

    return this.formatDecimal(roundedValue);
  }

  private formatInteger(value: number): string {
    if (value >= 1000) {
      return this.formatWithCommas(value);
    }
    return value.toString();
  }

  private formatDecimal(roundedValue: number): string {
    return roundedValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  private formatWithCommas(value: number): string {
    return new Intl.NumberFormat('ja-JP', {
      maximumSignificantDigits: 21,
    }).format(value);
  }
}
