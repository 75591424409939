import { Pipe, PipeTransform } from '@angular/core';
import { Timezones } from '@rewaa-team/rewaa-common';
import { DateTime } from 'luxon';
import { getClientTimeZone } from '../utility/timezone.utility';

@Pipe({
  name: 'ledgerReferenceIdDisplay',
})
export class LedgerReferenceIdDisplayPipe implements PipeTransform {
  transform(id: any, type: string, metadata?: Record<string, unknown>): any {
    if (type === 'expense') {
      return `Exp-${id}`;
    }
    if (type === 'stock-count' && metadata?.invoiceNumber) {
      return metadata?.invoiceNumber;
    }
    if (
      type === 'CustomerReceiveDebit' &&
      metadata?.transactionDate &&
      metadata?.messageRequestSequenceNumber &&
      metadata.transactionDate
    ) {
      const clientTimeZone = getClientTimeZone();
      const transactionDate: string = DateTime.fromISO(
        metadata.transactionDate as string,
        {
          zone: Timezones.UTC,
        },
      )
        .setZone(clientTimeZone)
        .toFormat('yyyyMMdd');
      return `PCDeb${transactionDate}-${metadata?.messageRequestSequenceNumber}`;
    }
    return id;
  }
}
