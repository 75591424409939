import { OfflinePosTypes } from '@rewaa-team/pos-sdk';
import { PosInvoiceEnums } from '@rewaa-team/types';
import { TrackTypeV2 } from '../model/invoice/StockOrderItem';
import {
  CreateTransferStockDTO,
  CreateTransferStockDestination,
  CreateTransferStockProductVariant,
  CreateTransferStockTrackInfo,
} from '../types/create-transfer-stock-type';
import {
  ProductTypeForStockTransfer,
  TransferStockStatus,
} from '../types/transfer-stock-status';
import { Invoice } from '../model/invoice/Invoice';
import {
  TransferStockDetails,
  TransferStockV2,
  TransferStockVariantToTracks,
} from '../types/TransferStock';
import {
  RequestTransferStockDTO,
  RequestTransferStockDestination,
  RequestTransferStockProductVariant,
} from '../types/request-transfer-stock-type';
import {
  AcceptTransferStockDTO,
  AcceptTransferStockProductVariant,
  TrackInfo,
} from '../types/accept-transfer-stock-type';
import { RejectTransferStockDTO } from '../types/reject-transfer-stock-type';
import { mapSortDirectionToV2 } from '../../invoices/services/mapper-functions-v2';
import {
  IInvoiceSearchFilter,
  IInvoiceSearchFilterV2,
} from '../model/invoice/invoice-search-and-filter';
import { Filter } from '../model/supplier-search-and-filter';
import { VariantToInvoice } from '../model/invoice/VariantToInvoice';
import { VariantToTrack } from '../model/VariantToTrack';
import { TRANSFER_STOCK_STATUSES } from '../constants';
import { UpdateTransferStockDTO } from '../types/update-trransfer-stock-type';
import { PaymentStatusV2 } from '../../invoices/purchase-order-new/common/constants';

export const transferStockStatusV1ToV2 = {
  [TRANSFER_STOCK_STATUSES.ACCEPTED]: TransferStockStatus.Accepted,
  [TRANSFER_STOCK_STATUSES.REJECTED]: TransferStockStatus.Rejected,
  [TRANSFER_STOCK_STATUSES.DRAFT]: TransferStockStatus.Draft,
  [TRANSFER_STOCK_STATUSES.REQUESTED]: TransferStockStatus.Requested,
  [TRANSFER_STOCK_STATUSES.PENDING]: TransferStockStatus.Pending,
  [TRANSFER_STOCK_STATUSES.PARTIALLY_ACCEPTED]:
    TransferStockStatus.PartiallyAccepted,
};

export const transferStockStatusV2ToV1 = {
  [TransferStockStatus.Accepted]: TRANSFER_STOCK_STATUSES.ACCEPTED,
  [TransferStockStatus.Rejected]: TRANSFER_STOCK_STATUSES.REJECTED,
  [TransferStockStatus.Draft]: TRANSFER_STOCK_STATUSES.DRAFT,
  [TransferStockStatus.Requested]: TRANSFER_STOCK_STATUSES.REQUESTED,
  [TransferStockStatus.Pending]: TRANSFER_STOCK_STATUSES.PENDING,
  [TransferStockStatus.PartiallyAccepted]:
    TRANSFER_STOCK_STATUSES.PARTIALLY_ACCEPTED,
};

export function mapDataForTheTrackStock(
  variantsToTrack: any,
): CreateTransferStockTrackInfo[] {
  const variantsToTrackData: CreateTransferStockTrackInfo[] = [];
  if (variantsToTrack) {
    for (const i of variantsToTrack) {
      variantsToTrackData.push({
        trackType: i.isSerial ? TrackTypeV2.Serial : TrackTypeV2.Batch,
        transferredQuantity: i.quantity,
        trackNo: i.trackNo,
      });
    }
  }
  return variantsToTrackData;
}

function getBuyPrice(
  productVariantToStockLocations: OfflinePosTypes.ProductVariantToStockLocation[],
  destinationLocationId: number,
): number {
  productVariantToStockLocations.forEach((element) => {
    if (element.stockLocationId === destinationLocationId) {
      return element.buyPrice;
    }
  });
  return 0;
}

function getCostPrice(
  productVariantToStockLocations: OfflinePosTypes.ProductVariantToStockLocation[],
  destinationLocationId: number,
): number {
  productVariantToStockLocations.forEach((element) => {
    if (element.stockLocationId === destinationLocationId) {
      return element.cost;
    }
  });
  return 0;
}

export function mapDataToTransferStockProductVariant(
  variants: any,
  compositeVariants: any,
  destinationLocationId: number,
): CreateTransferStockProductVariant[] {
  const productVariants: CreateTransferStockProductVariant[] = [];
  const totalVariants = variants.concat(compositeVariants);
  totalVariants.forEach((element) => {
    productVariants.push({
      requestedQuantity: element.requestedQuantity,
      transferredQuantity:
        element.transferQuantity || element.requestedQuantity,
      productVariantId: element.id,
      costPrice:
        element.type === 'composite' ? element.oldCost : element.newCost,
      productVariantName: element.name,
      trackType: element.trackType,
      sku: element.sku,
      type: element.type,
      buyPrice: getBuyPrice(
        element.ProductVariantToStockLocations,
        destinationLocationId,
      ),
      trackInfo: element.VariantToInvoiceTracks
        ? mapDataForTheTrackStock(element.VariantToInvoiceTracks)
        : null,
    });
  });
  return productVariants;
}

export function mapCreateMultipleTransferStock(
  transactions: any[],
  status: TransferStockStatus,
): CreateTransferStockDTO {
  const destinations: CreateTransferStockDestination[] = [];
  transactions.forEach((element) => {
    destinations.push({
      destinationStockLocationId: element.destinationLocationId,
      destinationStockLocationName: element.destinationLocationName,
      productVariants: mapDataToTransferStockProductVariant(
        element.variants,
        element.compositeVariants,
        element.destinationLocationId,
      ),
    });
  });
  return {
    sourceStockLocationId: transactions[0].stockLocationId,
    sourceStockLocationName: transactions[0].stockLocationName,
    status,
    destinations,
    notes: transactions[0].notes,
  };
}

export function mapAcceptTrackV1ToV2(variantToTracks: any): TrackInfo[] {
  const trackInfos: TrackInfo[] = [];
  variantToTracks.forEach((element) => {
    trackInfos.push({
      id: element.id,
      receivedQuantity: +element.transferQuantity,
    });
  });
  return trackInfos;
}

export function mapAcceptV1ToV2(
  acceptPayload: any,
  skipValidation?: boolean,
): AcceptTransferStockDTO {
  const productVariants: AcceptTransferStockProductVariant[] = [];
  acceptPayload.VariantToInvoices.forEach((element) => {
    productVariants.push({
      id: element.productVariantId,
      receivedQuantity: +element.quantity,
      trackInfo: element.trackType
        ? mapAcceptTrackV1ToV2(element.VariantToInvoiceTracks)
        : [],
    });
  });
  return {
    skipValidation,
    receivingDate: acceptPayload.deliveryDate
      ? acceptPayload.deliveryDate
      : acceptPayload.receivingDate,
    productVariants,
  };
}

export function mapRejectV1ToV2(rejectPayload: {
  rejectReason: string;
}): RejectTransferStockDTO {
  return {
    rejectReason: rejectPayload.rejectReason,
  };
}

export function mapProductVariantsToTransferStockRequest(
  variants: any[],
  destinationLocationId: number,
): RequestTransferStockProductVariant[] {
  const productVariants: RequestTransferStockProductVariant[] = [];
  variants.forEach((element) => {
    productVariants.push({
      requestedQuantity: element.transferQuantity,
      productVariantId: element.productVariantId,
      costPrice: getCostPrice(
        element.ProductVariantToStockLocations,
        destinationLocationId,
      ),
      buyPrice: getBuyPrice(
        element.ProductVariantToStockLocations,
        destinationLocationId,
      ),
      productVariantName: element.name,
      sku: element.sku,
      type: element.type,
      trackType: element.trackType,
    });
  });
  return productVariants;
}

export function mapCreateRequestMultipleTransferStock(
  transactions: any[],
): RequestTransferStockDTO {
  const destinations: RequestTransferStockDestination[] = [];
  transactions.forEach((element) => {
    destinations.push({
      destinationStockLocationId: element.destinationLocationId,
      destinationStockLocationName: element.destinationLocationName,
      productVariants: mapProductVariantsToTransferStockRequest(
        [...element.variants, ...element.compositeVariants],
        element.destinationLocationId,
      ),
    });
  });
  return {
    notes: transactions[0].notes,
    destinations,
  };
}

export function mapTransferStockVariantToTracksToV1(
  trackItems: TransferStockVariantToTracks[],
): VariantToTrack[] {
  const v1Tracks = [];
  trackItems.forEach((element) => {
    v1Tracks.push({
      trackNo: element.trackNo,
      trackType: element.trackType,
      quantity: element.transferredQuantity,
      id: element.id,
    });
  });
  return v1Tracks;
}

export function mapTransferStockDetailsToVariantToInvoice(
  transferStockDetails: TransferStockDetails[],
): VariantToInvoice[] {
  const variantToInvoice = [];
  transferStockDetails.forEach((element) => {
    variantToInvoice.push({
      VariantToInvoiceTracks:
        element.trackType && element.trackType !== TrackTypeV2.Ecard
          ? mapTransferStockVariantToTracksToV1(
              element.transferStockVariantToTracks,
            )
          : null,
      invoiceId: element.transferStockId,
      quantity: element.receivedQuantity,
      transferQuantity: element.transferredQuantity,
      productVariantId: element.productVariantId,
      requestedQuantity: element.requestedQuantity,
      id: element.id,
      name: element.productVariantName,
      newCost: element.costPrice,
      oldCost: element.costPrice,
      buyPriceExclusive: element.buyPrice,
      sku: element.sku,
      availableLocationQuantity: element.sourceCurrentQuantity,
      availableDestnationLocationQuantity: element.destinationCurrentQuantity,
      ProductVariant: {
        id: element.productVariantId,
        name: element.productVariantName,
        quantity:
          typeof element.transferredQuantity === 'string'
            ? parseInt(element.transferredQuantity, 10)
            : element.transferredQuantity,
        cost: element.costPrice,
        sku: element.sku,
        trackType:
          element.trackType && element.trackType !== TrackTypeV2.Ecard
            ? element.trackType
            : null,
        type: element.type,
      } as unknown,
    });
  });
  return variantToInvoice;
}

export function mapUpdateTransferStock(
  transactions: any[],
): UpdateTransferStockDTO {
  let productVariants: CreateTransferStockProductVariant[] = [];
  transactions.forEach((element) => {
    productVariants = productVariants.concat(
      mapDataToTransferStockProductVariant(
        element.variants,
        element.compositeVariants,
        element.destinationLocationId,
      ),
    );
  });
  return {
    productVariants,
    sourceStockLocationId: transactions[0].stockLocationId,
    sourceStockLocationName: transactions[0].stockLocationName,
    notes: transactions[0].notes,
  };
}

export function mapTransferStockToInvoice(
  transferStock: TransferStockV2,
): Invoice {
  return {
    invoiceNumber: transferStock.invoiceNumber,
    status: transferStockStatusV2ToV1[transferStock.status],
    notes: transferStock.notes,
    invoiceType: PosInvoiceEnums.InvoiceType.TransferStock,
    destinationLocationId: transferStock.destinationStockLocationId,
    destinationLocationName: transferStock.destinationStockLocationName,
    id: transferStock.id,
    stockLocationId: transferStock.sourceStockLocationId,
    stockLocationName: transferStock.sourceStockLocationName,
    userId: transferStock.userId.toString(),
    userName: transferStock.username,
    completeDate: transferStock.receivingDate,
    deliveryDate: transferStock.receivingDate,
    createdAt: transferStock.createdAt,
    VariantToInvoices: mapTransferStockDetailsToVariantToInvoice(
      transferStock.transferStockDetails,
    ),
    updatedAt: transferStock.updatedAt,
    StockLocation: {
      name: transferStock.destinationStockLocationName,
      id: transferStock.destinationStockLocationId,
    },
  };
}

export function mapAllTransferStockToInvoices(
  transferStocks: TransferStockV2[],
): Invoice[] {
  const invoices: Invoice[] = [];
  transferStocks.forEach((element) => {
    invoices.push({
      invoiceNumber: element.invoiceNumber,
      status: transferStockStatusV2ToV1[element.status],
      notes: element.notes,
      invoiceType: PosInvoiceEnums.InvoiceType.TransferStock,
      destinationLocationId: element.destinationStockLocationId,
      destinationLocationName: element.destinationStockLocationName,
      id: element.id,
      stockLocationId: element.sourceStockLocationId,
      stockLocationName: element.sourceStockLocationName,
      userId: element.userId.toString(),
      userName: element.username,
      completeDate: element.receivingDate,
      deliveryDate: element.receivingDate,
      createdAt: element.createdAt,
      updatedAt: element.updatedAt,
      StockLocation: {
        name: element.destinationStockLocationName,
        id: element.destinationStockLocationId,
      },
    });
  });
  return invoices;
}

export function mapSortByToV2(values: {
  [name: string]: 'ASC' | 'DESC';
}): string {
  let sortBy = '';
  // eslint-disable-next-line guard-for-in
  for (const key in values) {
    if (key === 'stockLocationId') {
      sortBy += 'sourceStockLocationId';
    } else if (key === 'destinationLocationId') {
      sortBy += 'destinationStockLocationId';
    } else {
      sortBy += key;
    }
  }
  return sortBy;
}

export function mapFiltersToV2(params: IInvoiceSearchFilter): Filter[] {
  const filterList: Filter[] = [];
  const columnNames = [
    'destinationLocationId',
    'invoiceStatus',
    'search',
    'createdAt',
    'sourceLocationId',
    'updatedAt',
    'user',
  ];
  const invoiceStatuses = [];
  if (params.invoiceStatus && Array.isArray(params.invoiceStatus)) {
    params.invoiceStatus.forEach((element) => {
      invoiceStatuses.push(transferStockStatusV1ToV2[element]);
    });
  } else if (params.invoiceStatus && typeof params.invoiceStatus === 'string') {
    const statuses = params.invoiceStatus.split(',');
    statuses.forEach((element) => {
      invoiceStatuses.push(transferStockStatusV1ToV2[element]);
    });
  }
  columnNames.forEach((element) => {
    if (element in params) {
      if (!params[element]) {
        return;
      }
      if (element === 'destinationLocationId') {
        filterList.push({
          columnName: 'destinationStockLocationId',
          value:
            typeof params[element] === 'string'
              ? (params[element] as string)
                  .split(',')
                  .map((e) => parseInt(e, 10))
              : params[element],
          matchMode: 'in',
        });
      } else if (element === 'invoiceStatus') {
        filterList.push({
          columnName: 'status',
          value: invoiceStatuses,
          matchMode: 'in',
        });
      } else if (element === 'sourceLocationId') {
        filterList.push({
          columnName: 'sourceStockLocationId',
          value:
            typeof params[element] === 'string'
              ? (params[element] as unknown as string)
                  .split(',')
                  .map((e) => parseInt(e, 10))
              : params[element],
          matchMode: 'in',
        });
      } else if (element === 'updatedAt') {
        filterList.push({
          columnName: 'updatedAt',
          value: [params[element].fromDate, params[element].toDate],
          matchMode: 'between',
        });
      } else if (element === 'createdAt') {
        filterList.push({
          columnName: 'createdAt',
          value: [params[element].fromDate, params[element].toDate],
          matchMode: 'between',
        });
      } else if (element === 'user') {
        filterList.push({
          columnName: 'userId',
          value:
            typeof params[element] === 'string'
              ? (params[element] as unknown as string)
                  .split(',')
                  .map((e) => parseInt(e, 10))
              : params[element],
          matchMode: 'in',
        });
      } else if (element === 'search') {
        filterList.push({
          columnName: 'invoiceNumber',
          value: params[element],
          matchMode: 'like',
        });
      }
    }
  });
  return filterList;
}

export function mapTransferStockQueryParamsToV2(
  params: IInvoiceSearchFilter,
): IInvoiceSearchFilterV2 {
  return {
    sortBy: mapSortByToV2(params.sortBy),
    sortDirection: mapSortDirectionToV2(params.sortBy),
    limit: params.limit,
    offset: params.offset,
    filters: JSON.stringify(mapFiltersToV2(params)),
  };
}

export function mapQueryParamsToReturnV2(
  supplierId: number,
): IInvoiceSearchFilterV2 {
  return {
    filters: JSON.stringify([
      {
        columnName: 'paymentStatus',
        value: [PaymentStatusV2.NOT_PAID, PaymentStatusV2.PARTIALLY_PAID],
        matchMode: 'in',
      },
      {
        columnName: 'supplierId',
        value: [supplierId],
        matchMode: 'in',
      },
    ]),
    sortBy: '',
    offset: '',
    limit: '',
  };
}
