import { Observable } from 'rxjs';

export interface IDownloadFile {
  filename: string;
  downloadUrl: string;
}

export function downloadFileFromUrl(file: IDownloadFile): Observable<void> {
  return new Observable<void>((observer) => {
    const downloadAnchorTag = document.createElement('a');
    downloadAnchorTag.setAttribute('href', file.downloadUrl);
    downloadAnchorTag.setAttribute('download', file.filename);
    document.body.appendChild(downloadAnchorTag);
    downloadAnchorTag.click();
    observer.next();
    observer.complete();
  });
}
