export enum Tags {
  warning = 'warning',
  info = 'info',
  error = 'error',
  success = 'success',
  waiting = 'waiting',
  expired = 'expired',
  purple = 'purple',
  green = 'green',
  gray = 'gray',
  gold = 'gold',
}

export type TagTypes = Tags;
