import { ProductEnums } from '@rewaa-team/types';
import { CommonCalculationService } from '../common/common-calculations.service';
import {
  CartActionConstant,
  CartActionType,
  DiscountTypeConstant,
  ErrorCodes,
  ErrorCodesConstant,
  MaxAllowed,
} from './enums';
import {
  Cart,
  Composite,
  Discount,
  Extra,
  ExtraLineItem,
  LineItem,
  LineItemError,
  LineItemErrors,
  Pack,
  QuoteLineItemError,
  ValidationErrors,
  Variant,
  VariantStock,
  VariantStockDetails,
} from './types';
import { AmountTypeConstant } from '../common/enums';
import { PromotionDiscountTypeConstant } from '../offline/enums';
import {
  checkStock,
  initializeStock,
  updateAvailableQuantityForTrackedProduct,
} from '../utils/cart-validation.utils';

class CartValidationService extends CommonCalculationService {
  private readonly quoteLineItemErrors: ErrorCodes[] = [
    ErrorCodesConstant.InvalidProductTypeForQuote,
    ErrorCodesConstant.InvalidTrackTypeForQuote,
  ];

  private readonly payLineItemErrors: ErrorCodes[] = [
    ErrorCodesConstant.CostGreaterThanPrice,
    ErrorCodesConstant.InsufficientStock,
    ErrorCodesConstant.InsufficientStock,
    ErrorCodesConstant.OutOfStock,
    ErrorCodesConstant.PriceExceeded,
    ErrorCodesConstant.QuantityExceeded,
  ];

  // checks before adding a product to cart
  isItemInStock(lineItem: LineItem | Variant): boolean {
    return !lineItem.manageStockLevel || lineItem.availableQuantity > 0;
  }

  isSupported(variant: Variant): boolean {
    // batch + extra
    // serial + extra
    // Composite + batch
    // Composite + serial
    return !(
      (variant.trackDetails.length > 0 &&
        (variant.productType === ProductEnums.ProductType.Composite ||
          variant.extras.length > 0)) ||
      variant.extras.find((extra: Extra) => !this.isSupportedExtra(extra))
    );
  }

  private isSupportedExtra(extra: Extra): boolean {
    return (
      !extra.unit &&
      (!extra.isPartOfOtherProduct ||
        extra.productType === ProductEnums.ProductType.Simple ||
        extra.productType === ProductEnums.ProductType.Variable)
    );
  }

  // validation on cart
  validateCart(cart: Cart): ValidationErrors {
    const { validationSettings } = cart;
    const errors: ValidationErrors = {
      lineItemErrors: [],
    };
    let lineItemDiscountErrors: LineItemErrors = [];
    let costGreaterThanPriceErrors: LineItemErrors = [];
    let insufficientStockErrors: LineItemErrors = [];
    let quantityExceededErrors: LineItemErrors = [];
    let priceExceededErrors: LineItemErrors = [];
    let quoteProductTypeErrors: LineItemErrors = [];
    let quoteTrackTypeErrors: LineItemErrors = [];

    if (validationSettings.discountLimit < 1) {
      if (cart.discount.rate > validationSettings.discountLimit) {
        errors[ErrorCodesConstant.DiscountLimitExceeded] = {
          discount: cart.discount.rate,
          discountLimit: validationSettings.discountLimit,
        };
      } else {
        lineItemDiscountErrors = this.getLineItemDiscountErrors(
          cart.lineItems,
          validationSettings.discountLimit,
        );
        const [firstDiscountError] = lineItemDiscountErrors;
        if (
          firstDiscountError &&
          firstDiscountError[ErrorCodesConstant.DiscountLimitExceeded]
        ) {
          errors[ErrorCodesConstant.DiscountLimitExceeded] =
            firstDiscountError[ErrorCodesConstant.DiscountLimitExceeded];
        }
      }
    }

    if (!validationSettings.allowCostGreaterThanPrice) {
      costGreaterThanPriceErrors = this.getCostGreaterThanPriceErrors(
        cart.lineItems,
      );
    }

    insufficientStockErrors = this.getInsufficientStockErrors(
      cart.lineItems,
      validationSettings.allowInsufficientStock,
    );

    quantityExceededErrors = this.getQuantityExceededErrors(cart.lineItems);

    priceExceededErrors = this.getPriceExceededErrors(cart.lineItems);

    quoteProductTypeErrors = this.getQuoteProductTypeErrors(cart.lineItems);
    quoteTrackTypeErrors = this.getQuoteTrackTypeErrors(cart.lineItems);

    errors.lineItemErrors = this.mergeLineItemErrors(
      cart.lineItems,
      lineItemDiscountErrors,
      costGreaterThanPriceErrors,
      insufficientStockErrors,
      quantityExceededErrors,
      priceExceededErrors,
      quoteProductTypeErrors,
      quoteTrackTypeErrors,
    );

    return errors;
  }

  mapLineItemErrors(errors: LineItemErrors, action: CartActionType): boolean[] {
    return errors.map((error) => {
      const keys = Object.keys(error);
      const actionErrors =
        action === CartActionConstant.Pay
          ? this.payLineItemErrors
          : this.quoteLineItemErrors;

      return keys.some((key) => actionErrors.includes(key as ErrorCodes));
    });
  }

  mergeLineItemErrors(
    lineItems: LineItem[],
    lineItemDiscountErrors: LineItemErrors,
    costGreaterThanPriceErrors: LineItemErrors,
    insufficientStockErrors: LineItemErrors,
    quantityExceedErrors: LineItemErrors,
    priceExceedErrors: LineItemErrors,
    quoteProductTypeErrors: LineItemErrors,
    quoteTrackTypeErrors: LineItemErrors,
  ): LineItemErrors {
    return lineItems.map((_lineItem, index): LineItemError => {
      const error: LineItemError = {};
      Object.assign(error, lineItemDiscountErrors[index]);
      Object.assign(error, costGreaterThanPriceErrors[index]);
      Object.assign(error, insufficientStockErrors[index]);
      Object.assign(error, quantityExceedErrors[index]);
      Object.assign(error, priceExceedErrors[index]);
      Object.assign(error, quoteProductTypeErrors[index]);
      Object.assign(error, quoteTrackTypeErrors[index]);
      return error;
    });
  }

  private getQuoteTrackTypeErrors(lineItems: LineItem[]): LineItemErrors {
    return lineItems.map((lineItem: LineItem): QuoteLineItemError => {
      const error: QuoteLineItemError = {};

      if (lineItem.trackType) {
        return {
          [ErrorCodesConstant.InvalidTrackTypeForQuote]: {
            type: lineItem.trackType,
          },
        };
      }

      return error;
    });
  }

  private getQuoteProductTypeErrors(lineItems: LineItem[]): LineItemErrors {
    return lineItems.map((lineItem: LineItem): QuoteLineItemError => {
      const error: QuoteLineItemError = {};

      if (lineItem.productType === ProductEnums.ProductType.ECard) {
        return {
          [ErrorCodesConstant.InvalidProductTypeForQuote]: {
            type: lineItem.productType,
          },
        };
      }

      return error;
    });
  }

  private getLineItemDiscountErrors(
    lineItems: LineItem[],
    discountLimit: number,
  ): LineItemErrors {
    return lineItems.map((lineItem: LineItem): LineItemError => {
      const productDiscount = lineItem.discounts.find(
        (discount: Discount) => discount.type === DiscountTypeConstant.Product,
      );
      if (productDiscount && productDiscount?.rate > discountLimit) {
        const discountError = {
          discount: productDiscount.rate,
          discountLimit: discountLimit,
        };
        return {
          [ErrorCodesConstant.DiscountLimitExceeded]: discountError,
        };
      }
      return {};
    });
  }

  private getCostGreaterThanPriceErrors(lineItems: LineItem[]): LineItemErrors {
    return lineItems.map((lineItem: LineItem): LineItemError => {
      const invoiceDiscount = lineItem.discounts.find(
        (discount: Discount) => discount.type === DiscountTypeConstant.Invoice,
      );
      const rate = invoiceDiscount?.rate || 0;
      const price = this.calculateDiscountedPrice(
        lineItem.priceTaxExclusive.final,
        rate,
        AmountTypeConstant.Percentage,
      );
      const { cost } = lineItem;
      const promotionType = lineItem.promotionDetails[0]?.discountType;
      if (
        cost > price &&
        promotionType !== PromotionDiscountTypeConstant.Free
      ) {
        return {
          [ErrorCodesConstant.CostGreaterThanPrice]: {
            cost,
            price,
          },
        };
      } else return {};
    });
  }

  private setEcardVariantStockDetails(
    variantStock: VariantStock,
    lineItem: LineItem,
  ): void {
    if (
      lineItem.productType === ProductEnums.ProductType.ECard &&
      lineItem.quantity > lineItem.eCards.length
    ) {
      const stock: VariantStockDetails =
        variantStock[lineItem.variantId] ||
        initializeStock(0, lineItem.eCards.length);
      stock.quantity = this.add(stock.quantity, 1);
      variantStock[lineItem.variantId] = stock;
    }
  }

  private setTrackedVariantStockDetails(
    variantStock: VariantStock,
    lineItem: LineItem,
  ) {
    if (!lineItem.trackType) return;

    const { variantId, quantity, type, trackDetails } = lineItem;
    const { VariantType } = ProductEnums;

    const stock: VariantStockDetails =
      variantStock[variantId] || initializeStock(quantity, 0);
    updateAvailableQuantityForTrackedProduct(stock, trackDetails);

    // Update stock quantity for non-package variants when no existing stock
    if (type !== VariantType.Package && variantStock[variantId])
      stock.quantity += quantity;

    variantStock[variantId] = stock;
  }

  private setNonTrackedVariantStockDetails(
    variantStock: VariantStock,
    lineItem: LineItem,
  ) {
    if (
      lineItem.trackDetails.length === 0 &&
      lineItem.productType !== ProductEnums.ProductType.ECard
    ) {
      const stock: VariantStockDetails =
        variantStock[lineItem.variantId] ||
        initializeStock(0, lineItem.availableQuantity);
      stock.quantity += lineItem.quantity;
      variantStock[lineItem.variantId] = stock;
    }
  }

  private setExtraVariantStockDetails(
    variantStock: VariantStock,
    lineItem: LineItem,
  ) {
    lineItem.extras.forEach((extra: ExtraLineItem) => {
      if (extra.isPartOfOtherProduct) {
        const stock: VariantStockDetails =
          variantStock[extra.variantId] ||
          initializeStock(0, extra.availableQuantity);
        stock.quantity = this.add(stock.quantity, extra.quantity);
        variantStock[extra.variantId] = stock;
      }
    });
  }

  private setCompositeVariantStockDetails(
    variantStock: VariantStock,
    lineItem: LineItem,
  ) {
    // composites // for the variants that make the composite
    lineItem.composites.forEach((composite: Composite) => {
      const stock: VariantStockDetails =
        variantStock[composite.variantId] ||
        initializeStock(0, composite.availableQuantity);
      stock.quantity = this.add(
        this.multiply(lineItem.quantity, composite.rate),
        stock.quantity,
      );
      variantStock[composite.variantId] = stock;

      // for composites of packs
      const pack = lineItem.packs.find(
        (pack: Pack) => pack.id === composite.variantId,
      );
      if (pack) {
        const stock: VariantStockDetails =
          variantStock[pack.variantId] ||
          initializeStock(0, pack.availableQuantity);
        stock.quantity = this.add(
          this.multiply(
            this.multiply(lineItem.quantity, composite.rate),
            pack.rate,
          ),
          stock.quantity,
        );
        variantStock[pack.variantId] = stock;
      }
    });
  }

  private setPackVariantStockDetails(
    variantStock: VariantStock,
    lineItem: LineItem,
  ) {
    // packs // for the variants that makes the packs
    lineItem.packs.forEach((pack: Pack) => {
      const stock: VariantStockDetails =
        variantStock[pack.variantId] ||
        initializeStock(0, pack.availableQuantity);
      if (lineItem.trackType) {
        updateAvailableQuantityForTrackedProduct(
          stock,
          lineItem.trackDetails,
          pack.rate,
        );
      }
      stock.quantity = this.add(
        this.multiply(lineItem.quantity, pack.rate),
        stock.quantity,
      );
      variantStock[pack.variantId] = stock;
    });
  }

  private getInsufficientStockErrors(
    lineItems: LineItem[],
    allowInsufficientStock: boolean,
  ): LineItemErrors {
    const variantStock: VariantStock = {};
    lineItems.forEach((lineItem: LineItem) => {
      this.setTrackedVariantStockDetails(variantStock, lineItem);
      this.setEcardVariantStockDetails(variantStock, lineItem);
      if (allowInsufficientStock) {
        return;
      }
      this.setNonTrackedVariantStockDetails(variantStock, lineItem);
      this.setExtraVariantStockDetails(variantStock, lineItem);
      this.setPackVariantStockDetails(variantStock, lineItem);
      this.setCompositeVariantStockDetails(variantStock, lineItem);
    });

    return lineItems.map((lineItem: LineItem): LineItemError => {
      if (lineItem.type === ProductEnums.VariantType.Package) {
        for (const pack of lineItem.packs) {
          const stock = variantStock[pack.variantId];
          const stockError = checkStock(stock, lineItem.manageStockLevel);
          if (stockError) return stockError;
        }
        return {};
      } else {
        const stock: VariantStockDetails = variantStock[lineItem.variantId];
        const stockError = checkStock(stock, lineItem.manageStockLevel);
        if (stockError) return stockError;
        return {};
      }
    });
  }

  private getQuantityExceededErrors(lineItems: LineItem[]): LineItemErrors {
    return lineItems.map((lineItem: LineItem): LineItemError => {
      const quantity = lineItem.quantity;
      if (quantity > MaxAllowed.Quantity) {
        return {
          [ErrorCodesConstant.QuantityExceeded]: quantity,
        };
      }
      const { extras = [] } = lineItem;
      const extrasExceededQuantities = this.getExtrasExceededQuantities(extras);
      if (!extrasExceededQuantities.length) {
        return {};
      }
      return {
        [ErrorCodesConstant.QuantityExceeded]: extrasExceededQuantities[0],
      };
    });
  }

  private getPriceExceededErrors(lineItems: LineItem[]): LineItemErrors {
    return lineItems.map((lineItem: LineItem): LineItemError => {
      const price = lineItem.priceTaxExclusive.final;
      if (price <= MaxAllowed.Price) {
        return {};
      }
      return {
        [ErrorCodesConstant.PriceExceeded]: price,
      };
    });
  }

  private getExtrasExceededQuantities(extras: ExtraLineItem[]): number[] {
    const exceededQuantities: number[] = [];
    extras.forEach((extra: ExtraLineItem) => {
      const { quantity } = extra;
      if (quantity > MaxAllowed.Quantity) {
        exceededQuantities.push(quantity);
      }
    });
    return exceededQuantities;
  }
}

export const cartValidationService = new CartValidationService();
