import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PaginationQuery } from 'src/app/shared/model/PaginationQuery';
import { DateTime } from 'luxon';
import { TaxType } from '@rewaa-team/rewaa-common';
import {
  downloadFileFromUrl,
  IDownloadFile,
} from '../../shared/utility/file.utility';
import { Expense } from '../model/expense';
import {
  ExpenseCategories,
  ExpenseStopResponse,
  Identifier,
  LocationIdentifier,
  PaymentMethodIdentifier,
  RegisterIdentifier,
  TaxMethodIdentifier,
} from '../model/expense.types';
import { ExpenseExport, ExpenseExportViaImex } from './types';
import { environment } from '../../../environments/environment';

const API_URL = '/api';
const EXPENSE_SERVICE_API_URL = environment.expenseApiPath;

const EXPENSE_API_URL = environment.expenseApiPath;

@Injectable()
export class ExpenseService {
  constructor(private http: HttpClient) {}

  getExpense(
    expenseId: number | string,
    expenseV2Enabled: boolean,
  ): Observable<Expense> {
    if (expenseV2Enabled) {
      return this.http
        .get<Expense>(`${EXPENSE_API_URL}/expenses/${expenseId}`)
        .pipe(map((response) => new Expense(this.mapGetExpenseV2(response))));
    }
    return this.http
      .get<Expense>(`${API_URL}/expenses/${expenseId}`)
      .pipe(map((response) => new Expense(response)));
  }

  mapGetExpenseV2(response) {
    const locationIdSet = new Set<number>();
    response.payments.forEach((p) => {
      if (!locationIdSet.has(p.locationId)) {
        locationIdSet.add(p.locationId);
      }
    });
    const locationIds = Array.from(locationIdSet);
    const locations = locationIds.map((locationId) => ({
      stockLocationId: locationId,
    }));
    response.paymentDate = response.paymentDateTime;
    response.taxable = response.isTaxable;
    response.isTaxInclusive = response.taxType === TaxType.TaxInclusive;
    response.ExpenseCategory = response.category;
    response.expenseCategoryId = response.category?.ulid;
    response.ExpensePayments = response.payments;
    response.ExpenseStockLocations = locations;
    return response;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getAllExpenses(query): Observable<{ result: Expense[]; total: number }> {
    const productQueryParams = new HttpParams()
      .set('id', query.id ?? '')
      .set('offset', query.offset ? query.offset : 0)
      .set('limit', query.limit ?? '')
      .set('excludeChildRecords', query.excludeChildRecords ?? true)
      .set('parentId', query.parentId ?? '')
      .set('search', query.search ?? '')
      .set('searchOP', query.searchOP ?? '')
      .set('sortBy', query.sortBy ?? '')
      .set('amount', query.amount ?? '')
      .set('amountOP', query.amountOP ?? '')
      .set('taxAmount', query.taxAmount ?? '')
      .set('taxAmountOP', query.taxAmountOP ?? '')
      .set('expenseCategory', query.expenseCategory ?? '')
      .set('location', query.location ?? '')
      .set('paymentMethod', query.paymentMethod ?? '')
      .set('taxable', query.taxable ?? '')
      .set('isRecurring', query.isRecurring ?? '')
      .set('recurringCycle', query.recurringCycle ?? '')
      .set('recurringStartDateFromDate', query.recurringStartDateFromDate ?? '')
      .set('recurringStartDateToDate', query.recurringStartDateToDate ?? '')
      .set('recurringEndDateFromDate', query.recurringEndDateFromDate ?? '')
      .set('recurringEndDateToDate', query.recurringEndDateToDate ?? '')
      .set('fromDate', query.fromDate ?? '')
      .set('toDate', query.toDate ?? '')
      .set('createdAtFromDate', query.createdAtFromDate ?? '')
      .set('createdAtToDate', query.createdAtToDate ?? '')
      .set('updatedAtFromDate', query.updatedAtFromDate ?? '')
      .set('updatedAtToDate', query.updatedAtToDate ?? '');

    return this.http.get<{ result: Expense[]; total: number }>(
      `${API_URL}/expenses`,
      { params: productQueryParams },
    );
  }

  mapSorting(key: string): string {
    if (key === 'id') {
      return 'expenseNumber';
    }
    if (key === 'expenseCategory') {
      return 'category';
    }
    return key;
  }

  getAllExpensesV2(query): Observable<{ resultSet: Expense[]; total: number }> {
    const sort = JSON.parse(query.sortBy);
    if (sort) {
      const entries = Object.entries(sort);
      const [sortOn, sortBy] = entries[0];
      query.sortOn = this.mapSorting(sortOn);
      query.sortBy = (sortBy as string).toUpperCase();
    } else {
      query.sortOn = 'createdAt';
      query.sortBy = 'DESC';
    }
    let expenseQueryParams = new HttpParams()
      .set('offset', query.offset ? query.offset : 0)
      .set('limit', query.limit ?? '')
      .set('search', query.search ?? '')
      .set('searchOP', query.searchOP ?? '')
      .set('sortOn', query.sortOn ?? '')
      .set('sortBy', query.sortBy ?? '')
      .set('amount', query.amount !== undefined ? query.amount : '')
      .set('taxAmount', query.taxAmount !== undefined ? query.taxAmount : '')
      .set('createdAtFromDate', query.createdAtFromDate ?? '')
      .set('createdAtToDate', query.createdAtToDate ?? '')
      .set('updatedAtFromDate', query.updatedAtFromDate ?? '')
      .set('updatedAtToDate', query.updatedAtToDate ?? '');
    if (query.expenseCategory) {
      query.expenseCategory.split(',').forEach((categoryUlid) => {
        expenseQueryParams = expenseQueryParams.append(
          'categoryUlids[]',
          categoryUlid,
        );
      });
    }
    if (query.location) {
      query.location.split(',').forEach((locationId) => {
        expenseQueryParams = expenseQueryParams.append(
          'locationIds[]',
          locationId,
        );
      });
    }
    if (query.paymentMethod !== undefined) {
      expenseQueryParams = expenseQueryParams.set(
        'paymentMethodIds[]',
        query.paymentMethod,
      );
    }
    if (query.taxAmountOP !== undefined) {
      expenseQueryParams = expenseQueryParams.set(
        'taxAmountOp',
        query.taxAmountOP ?? '',
      );
    }
    if (query.amountOP !== undefined) {
      expenseQueryParams = expenseQueryParams.set('amountOp', query.amountOP);
    }
    if (query.id !== undefined) {
      expenseQueryParams = expenseQueryParams.set(
        'expenseNumber',
        query.id ?? '',
      );
    }
    if (query.taxable !== undefined) {
      expenseQueryParams = expenseQueryParams.set('taxable', query.taxable);
    }
    if (query.fromDate !== undefined) {
      expenseQueryParams = expenseQueryParams.set(
        'paymentDateFrom',
        query.fromDate ?? '',
      );
    }
    if (query.toDate !== undefined) {
      expenseQueryParams = expenseQueryParams.set(
        'paymentDateTo',
        query.toDate ?? '',
      );
    }
    console.log('Query Params: ', expenseQueryParams);
    return this.http.get<{ resultSet: Expense[]; total: number }>(
      `${EXPENSE_API_URL}/expenses`,
      {
        params: expenseQueryParams,
      },
    );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  exportAllExpenses({
    lang,
    search,
    searchOP,
    sortBy,
    amount,
    amountOP,
    expenseCategory,
    location,
    paymentMethod,
    taxable,
    taxAmount,
    taxAmountOP,
    fromDate,
    toDate,
    createdAtFromDate,
    createdAtToDate,
    updatedAtFromDate,
    updatedAtToDate,
  }: ExpenseExport): Observable<IDownloadFile> {
    const productQueryParams = new HttpParams()
      .set('lang', lang || '')
      .set('search', search || '')
      .set('searchOP', searchOP || '')
      .set('sortBy', sortBy || '')
      .set('amount', amount || '')
      .set('amountOP', amountOP || '')
      .set('expenseCategory', expenseCategory || '')
      .set('location', location || '')
      .set('paymentMethod', paymentMethod || '')
      .set('taxable', taxable || '')
      .set('taxAmount', taxAmount || '')
      .set('taxAmountOP', taxAmountOP || '')
      .set('fromDate', fromDate || '')
      .set('toDate', toDate || '')
      .set('createdAtFromDate', createdAtFromDate || '')
      .set('createdAtToDate', createdAtToDate || '')
      .set('updatedAtFromDate', updatedAtFromDate || '')
      .set('updatedAtToDate', updatedAtToDate || '');

    return this.http
      .get<IDownloadFile>(`${API_URL}/expenses/export`, {
        params: productQueryParams,
      })
      .pipe(tap((response) => downloadFileFromUrl(response).subscribe()));
  }

  getExpenseCategories(
    expenseV2Enabled = false,
  ): Observable<ExpenseCategories[]> {
    if (expenseV2Enabled) {
      return this.http
        .get<ExpenseCategories[]>(`${EXPENSE_API_URL}/expense-categories`)
        .pipe(
          map((response) =>
            response.map(
              (e) =>
                <ExpenseCategories>{
                  ulid: e.ulid,
                  name: e.name,
                  slug: e.slug,
                  state: e.state,
                  isLeaf: e.isLeaf,
                },
            ),
          ),
        );
    }
    return this.http
      .get<ExpenseCategories[]>(`${API_URL}/expense-categories`)
      .pipe(
        map((response) =>
          response.map(
            (e) => <ExpenseCategories>{ id: e.id, name: e.name, slug: e.slug },
          ),
        ),
      );
  }

  exportViaImex(params: ExpenseExportViaImex): Observable<unknown> {
    return this.http.post(`${EXPENSE_SERVICE_API_URL}/expenses-export`, params);
  }

  getStockLocations(): Observable<LocationIdentifier[]> {
    return this.http
      .get<LocationIdentifier[]>(`${API_URL}/stock-location`)
      .pipe(
        map((response) =>
          response.map(
            (e) =>
              <LocationIdentifier>{
                id: e.id,
                name: e.name,
                isDefault: e.isDefault,
              },
          ),
        ),
      );
  }

  getStockLocationRegister(
    locationId: number,
  ): Observable<RegisterIdentifier[]> {
    return this.http
      .get<RegisterIdentifier[]>(
        `${API_URL}/stock-location/${locationId}/pos/registers`,
      )
      .pipe(
        map((response) =>
          response.map(
            (e) =>
              <RegisterIdentifier>{
                id: e.id,
                name: e.name,
                status: e.status,
                PaymentMethodToRegisters: e.PaymentMethodToRegisters,
              },
          ),
        ),
      );
  }

  getTaxes(): Observable<TaxMethodIdentifier[]> {
    return this.http.get<TaxMethodIdentifier[]>(`${API_URL}/taxes`).pipe(
      map((response) =>
        response.map(
          (e) =>
            <TaxMethodIdentifier>{
              id: e.id,
              name: e.name,
              rate: e.rate,
            },
        ),
      ),
    );
  }

  getTaxLists(): Observable<any[]> {
    return this.http.get<Identifier[]>(`${API_URL}/taxes`);
  }

  getTaxById(taxId: number): Observable<any> {
    return this.http.get(`${API_URL}/taxes/${taxId}`);
  }

  getPaymentMethods(): Observable<PaymentMethodIdentifier[]> {
    return this.http
      .get<PaymentMethodIdentifier[]>(`${API_URL}/payment-methods/list`)
      .pipe(
        map((response) =>
          response.map(
            (e) =>
              <PaymentMethodIdentifier>{
                id: e.id,
                name: e.name,
                type: e.type,
              },
          ),
        ),
      );
  }

  mapExpenseV2(payload) {
    const paymentDateTime = DateTime.fromFormat(
      payload.paymentDate,
      'yyyy-MM-dd',
    )
      .toUTC()
      .toISO();
    payload.paymentDateTime = paymentDateTime;
    payload.isTaxable = payload.taxable;
    payload.categoryUlid = payload.expenseCategoryId;
    payload.locationIds = payload.ExpenseStockLocations;
    payload.payments = payload.ExpensePayments;
    payload.costCenters = payload.costCenterIds;
    return payload;
  }

  createExpense(payload: Expense, expenseV2Enabled: boolean): Observable<any> {
    if (expenseV2Enabled) {
      payload = this.mapExpenseV2(payload);
      return this.http.post(`${EXPENSE_API_URL}/expenses`, payload);
    }
    return this.http.post(`${API_URL}/expenses/create`, payload);
  }

  updateExpense(payload: Expense, expenseV2Enabled: boolean): Observable<any> {
    if (expenseV2Enabled) {
      payload = this.mapExpenseV2(payload);
      return this.http.put(
        `${EXPENSE_API_URL}/expenses/${payload.ulid}`,
        payload,
      );
    }
    return this.http.put(`${API_URL}/expenses/${payload.id}`, payload);
  }

  getExpenses(
    pageQuery: PaginationQuery,
  ): Observable<{ result: Expense[]; total: number }> {
    return new Observable((subscriber) => {
      const { offset, limit, query = '' } = pageQuery;
      const requestPromise = firstValueFrom(
        this.http.get<{ result: Expense[]; total: number }>(
          `${API_URL}/expenses?query=${query}&limit=${limit}&offset=${offset}`,
        ),
      );

      requestPromise.then((data: { result: Expense[]; total: number }) => {
        subscriber.next(data);
      });
    });
  }

  deleteExpense(
    id: number | string,
    expenseV2Enabled: boolean,
  ): Observable<any> {
    if (expenseV2Enabled) {
      return this.http.delete(`${EXPENSE_API_URL}/expenses/${id}`);
    }
    return this.http.delete(`${API_URL}/expenses/${id}`);
  }

  stopExpense(id: number): Promise<ExpenseStopResponse> {
    return firstValueFrom(
      this.http.put<ExpenseStopResponse>(`${API_URL}/expenses/stop/${id}`, {}),
    );
  }
}
