<div class="start-time flex align-items-center justify-content-between gap-2">
  <div class="flex flex-grow-1">
  <span
    class="rw__input rw__input-float rw__input-float-md p-float-label minw-0 w-100"
  >
      <p-calendar
        inputId="dateFilterStartTime-input"
        id="dateFilterStartTime-input"
        [showIcon]="true"
        [readonlyInput]="isReadOnly"
        [timeOnly]="true"
        [hourFormat]="12"
        [showClear]="true"
        appendTo="body"
        [(ngModel)]="startDateTime"
        (ngModelChange)="onTimeRangeChange()"
        panelStyleClass="time-selector-container"
      ></p-calendar>
  <label for="dateFilterStartTime-input">
    {{ 'Start Time' | translate }}
  </label>
  </span>
  </div>
  <div>
    <div class="flex justify-content-end">
      <p-selectButton
        data-qa="discountDialog-discountTypeSelector"
        class="rw__select-button rw__select-button-switch"
        [options]="timeOptions"
        [(ngModel)]="activeStartTimeSelectOption"
        optionValue="value"
        (onOptionClick)="onTimeTypeClicked(true)"
      >
      </p-selectButton>
    </div>
  </div>
</div>

<div class="end-time flex align-items-center justify-content-between gap-2 rw__mt-5">
  <div class="flex flex-grow-1">
      <span
        class="rw__input rw__input-float rw__input-float-md p-float-label minw-0 w-100"
      >
  <p-calendar
    inputId="dateFilterEndTime-input"
    id="dateFilterEndTime-input"
    [showIcon]="true"
    [readonlyInput]="isReadOnly"
    [timeOnly]="true"
    [hourFormat]="12"
    [showClear]="true"
    appendTo="body"
    [(ngModel)]="endDateTime"
    (ngModelChange)="onTimeRangeChange()"
    panelStyleClass="time-selector-container"
  ></p-calendar>
  <label for="dateFilterEndTime-input">
    {{ 'End Time' | translate }}
  </label>
  </span>
  </div>
  <div>
    <div class="flex justify-content-end">
      <p-selectButton
        data-qa="discountDialog-discountTypeSelector"
        class="rw__select-button rw__select-button-switch"
        [options]="timeOptions"
        [(ngModel)]="activeEndTimeSelectOption"
        optionValue="value"
        (onOptionClick)="onTimeTypeClicked(false)"
      >
      </p-selectButton>
    </div>
  </div>
</div>
