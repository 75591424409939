import { InvoiceV2, Product } from '@rewaa-team/rewaa-common';
import { PosInvoiceTypes } from '@rewaa-team/types';
import {
  AdditionalCost,
  Attachment,
  Metadata,
  MetadataV2,
  PayPoResponseV2,
  PurchaseOrderApiInput,
  PurchaseOrderApiInputV2,
} from '../purchase-order-new/purchase-order-form/types';
import { Invoice } from '../../shared/model/invoice/Invoice';
import {
  StockOrderItem,
  TrackTypeV2,
  TrackItem,
} from '../../shared/model/invoice/StockOrderItem';
import { VariantToInvoice } from '../../shared/model/invoice/VariantToInvoice';
import {
  IInvoiceSearchFilter,
  IInvoiceSearchFilterV2,
} from '../../shared/model/invoice/invoice-search-and-filter';
import { Filter } from '../../shared/model/supplier-search-and-filter';
import { Tax } from '../../shared/model/Tax';
import { PayableInvoice } from '../../shared/model/invoice/PayableInvoice';
import { PaymentV2 } from '../../shared/model/invoice/Payment';
import {
  InvoiceStatusEnumV2,
  InvoiceStatusEnumV1,
  PaymentStatusV2,
  OrderTypeV2,
} from '../purchase-order-new/common/constants';
import { INVOICE_TYPES, PAYMENT_STATUSES } from '../../shared/constants';
import {
  PayCreditRequest,
  PayCreditRequestV2,
} from '../payment/model/pay-credit-request';
import { InvoicesCalculator } from '../utilities/invoices.calculator';

export function mapAdditionalCostV2ToV1(additionalCosts: AdditionalCost[]): {
  [x: string]: { value: number; tax?: Tax };
} {
  const final: { [x: string]: { value: number; tax?: Tax } } = {};
  additionalCosts.forEach((element) => {
    final[element.name] = {
      value: element.totalTaxExclusive,
      tax: element.taxType,
    };
  });
  return final;
}

export function mapV2MetadataToV1(
  metadata: MetadataV2,
  attachments: Attachment[],
  additionalCosts: AdditionalCost[],
): Metadata {
  return {
    additionalCosts: additionalCosts?.length
      ? mapAdditionalCostV2ToV1(additionalCosts)
      : null,
    attachments,
    taxType: metadata.taxType,
    costCenterIds: metadata.costCenterIds,
    payment: metadata?.payment || null,
  };
}

export function mapV2TrackItemsToV1(
  items: TrackItem[],
  stockLocationId: number,
  supplierId: number,
  productVariantId: number,
  ecard: boolean,
): PosInvoiceTypes.VariantToTrack[] {
  const vtt: PosInvoiceTypes.VariantToTrack[] = [];
  const eList: PosInvoiceTypes.VariantToTrack[] = [];
  items.forEach((element) => {
    if (ecard) {
      eList.push({
        id: element.id,
        trackNo: element.trackNumber,
        quantity: element.quantity,
        issueDate: element.issueDate ? new Date(element.issueDate) : null,
        expiryDate: element.issueDate ? new Date(element.expiryDate) : null,
        stockLocationId,
        supplierId,
        productVariantId,
      });
    } else {
      vtt.push({
        id: element.id,
        trackNo: element.trackNumber,
        quantity: element.quantity,
        issueDate: element.issueDate ? new Date(element.issueDate) : null,
        expiryDate: element.expiryDate ? new Date(element.expiryDate) : null,
        stockLocationId,
        supplierId,
        productVariantId,
      });
    }
  });
  if (ecard) {
    return eList;
  }
  return vtt;
}

export function mapV2TrackTypeToV1(trackType: TrackTypeV2): 'batch' | 'serial' {
  if (trackType === TrackTypeV2.Batch) {
    return 'batch';
  }
  if (trackType === TrackTypeV2.Serial) {
    return 'serial';
  }
}

export function mapV2VariantToInvoicesToV1(
  stockOrderItems: StockOrderItem[],
  stockLocationId: number,
  supplierId: number,
  stockLocationName: string,
  productVariantInfo?: {
    buyPrice: number;
    cost: number;
    initialCost: number;
    initialQuantity: number;
    productVariantId: number;
    quantity: number;
  }[],
): VariantToInvoice[] {
  const vti: VariantToInvoice[] = [];
  stockOrderItems.forEach((element) => {
    let trackType;
    if (element.trackType !== null) {
      trackType = mapV2TrackTypeToV1(element.trackType);
    } else {
      trackType = null;
    }
    let variant: {
      buyPrice: number;
      cost: number;
      initialCost: number;
      initialQuantity: number;
      productVariantId: number;
      quantity: number;
    };
    if (productVariantInfo?.length) {
      variant = productVariantInfo.find(
        (v) => v[0].productVariantId === element.variantId,
      )[0];
    }
    vti.push({
      ProductVariant: {
        stockLocationId,
        id: element.variantId,
        name: element.name,
        type: element.variantType,
        trackType,
        sku: element.sku,
        initialCost: variant ? variant.initialCost : element.oldCost,
        buyPrice: variant ? variant.buyPrice : element.buyPrice,
        cost: variant ? variant.cost : element.requisitionCost,
        ProductVariantToStockLocations: [
          {
            stockLocationId,
            variantID: element.variantId,
            quantity: productVariantInfo?.length
              ? variant.quantity
              : element.availableLocationQuantity,
            initialQuantity: productVariantInfo?.length
              ? variant.initialQuantity
              : element.availableLocationQuantity + element.requestedQuantity,
            locationName: stockLocationName,
            buyPrice: variant ? variant.buyPrice : element.buyPrice,
            sku: element.sku,
            cost: variant ? variant.cost : element.oldCost,
            Tax: element.tax,
          },
        ],
        Product: {
          type:
            element.trackType === TrackTypeV2.Ecard
              ? Product.Types.ProductType.ecard
              : null,
        },
      } as any,
      costExclusive: element.requisitionCost,
      buyPriceExclusive: element.buyPrice,
      id: element.id,
      sku: element.sku,
      name: element.name,
      quantity: element.requestedQuantity,
      changedQuantity:
        element.availableLocationQuantity - element.receivedQuantity,
      availableLocationQuantity: element.availableLocationQuantity,
      totalExclusive: element.requisitionCost * element.requestedQuantity,
      oldCost: element.oldCost,
      costInclusive: InvoicesCalculator.calculateInclusiveValue(
        element.requisitionCost,
        element.defaultTaxConfig,
      ),
      taxAmount:
        element.requisitionCost -
        InvoicesCalculator.calculateInclusiveValue(
          element.requisitionCost,
          element.defaultTaxConfig,
        ),
      discount: element.discountAmount,
      trackType,
      newCost: element.averageCost,
      VariantToInvoiceTracks:
        trackType === 'batch' || trackType === 'serial'
          ? mapV2TrackItemsToV1(
              element.stockOrderTrackedItems,
              stockLocationId,
              supplierId,
              element.variantId,
              false,
            )
          : [],
      productVariantId: element.variantId,
      VariantToInvoiceEcards:
        trackType === 'ecard'
          ? mapV2TrackItemsToV1(
              element.stockOrderTrackedItems,
              stockLocationId,
              supplierId,
              element.variantId,
              true,
            )
          : [],
      createdAt: element.createdAt,
      updatedAt: element.updatedAt,
      deletedAt: element.deletedAt,
      taxJson: JSON.stringify(element.defaultTaxConfig),
    });
  });
  return vti;
}

export const poInvoiceStatusV2ToV1 = {
  [InvoiceStatusEnumV2.OPEN]: InvoiceStatusEnumV1.OPEN,
  [InvoiceStatusEnumV2.COMPLETED]: InvoiceStatusEnumV1.COMPLETED,
};

export const poInvoiceStatusV1ToV2 = {
  [InvoiceStatusEnumV1.OPEN]: InvoiceStatusEnumV2.OPEN,
  [InvoiceStatusEnumV1.COMPLETED]: InvoiceStatusEnumV2.COMPLETED,
};

export const poPaymentStatusV2toV1 = {
  [PaymentStatusV2.PAID]: PAYMENT_STATUSES.PAID,
  [PaymentStatusV2.NOT_PAID]: PAYMENT_STATUSES.NOT_PAID_CREDITOR,
  [PaymentStatusV2.PARTIALLY_PAID]: PAYMENT_STATUSES.PARTIALLY_PAID_CREDITOR,
  [PaymentStatusV2.DRAFT]: PAYMENT_STATUSES.DRAFT,
};

export const poPaymentStatusV1toV2 = {
  [PAYMENT_STATUSES.PAID]: PaymentStatusV2.PAID,
  [PAYMENT_STATUSES.NOT_PAID_CREDITOR]: PaymentStatusV2.NOT_PAID,
  [PAYMENT_STATUSES.PARTIALLY_PAID_CREDITOR]: PaymentStatusV2.PARTIALLY_PAID,
  [PAYMENT_STATUSES.DRAFT]: PaymentStatusV2.DRAFT,
};

export const typeV2ToV1 = {
  [OrderTypeV2.PurchaseOrder]: INVOICE_TYPES.PURCHASE_ORDER,
  [OrderTypeV2.ReturnStock]: INVOICE_TYPES.RETURN_STOCK,
};

export function mapV2InvoiceToV1(poV2: InvoiceV2): Invoice {
  const paidAmount =
    poInvoiceStatusV2ToV1[poV2.status] === InvoiceStatusEnumV1.OPEN
      ? poV2.metadata?.payment?.paidAmount
      : poV2.paidAmount;
  return {
    completeDate: poV2.completedAt,
    status: poInvoiceStatusV2ToV1[poV2.status],
    createdAt: poV2.createdAt,
    notes: poV2.notes,
    type: 'PurchaseOrder',
    subTotalTaxExclusive: poV2.subTotalTaxExclusive,
    totalTax: poV2.totalTax,
    totalTaxInclusive: poV2.totalTaxInclusive,
    destinationLocationId: null,
    stockLocationName: poV2.stockLocationName,
    supplierName: poV2.supplier.name,
    customerName: '',
    supplierVatNumber: poV2.supplier.vatNumber,
    deliveryDate: poV2.expectedDeliveryDate,
    customerVatNumber: '',
    id: poV2.id,
    stockLocationId: poV2.stockLocationId,
    supplierId: poV2.supplierId,
    invoiceNumber: poV2.invoiceNumber,
    StockLocation: { name: poV2.stockLocationName, id: poV2.stockLocationId },
    userId: poV2.createdBy.toString(),
    metadata: mapV2MetadataToV1(
      poV2.metadata,
      poV2.attachments,
      poV2.additionalCosts,
    ),
    PayableInvoice: {
      paymentDueDate: poV2.paymentDueDate,
      paymentMethod: poV2.paymentLines
        ? Array.from(
            new Set(poV2.paymentLines.map((p) => p.paymentMethodName)),
          ).join(', ')
        : null,
      taxation: 'Exclusive',
      supplierInvoiceNumber: poV2.supplierInvoiceNumber,
      paymentStatus: poPaymentStatusV2toV1[poV2.paymentStatus],
      paymentOption: null,
      paidAmount,
      debitAmount: poV2.totalTaxInclusive - paidAmount,
      totalBeforePayment: null,
      totalAfterPayment: null,
      taxLinesJson: '[]',
      id: null,
      invoiceId: null,
      paymentMethodId:
        poInvoiceStatusV2ToV1[poV2.status] === InvoiceStatusEnumV1.OPEN
          ? poV2.metadata?.payment?.paymentMethod?.id
          : poV2?.paymentLines?.[0]?.paymentMethodId,
      discountAmount: poV2.discountAmount,
      discountType: poV2.discountType,
      PaymentToPayableInvoices: [
        {
          paymentId: 1,
          payableInvoiceId: 2,
          id: 2,
          Payment: {
            paidAmount: poV2.paidAmount,
          },
        },
      ] as any,
    },
    Supplier: poV2.supplier,
    VariantToInvoices: poV2.stockOrderItems
      ? mapV2VariantToInvoicesToV1(
          poV2.stockOrderItems,
          poV2.stockLocationId,
          poV2.supplierId,
          poV2.stockLocationName,
          poV2.productVariantInfo,
        )
      : [],
    totalPaidAmount: poV2.paidAmount,
    userName: 'Super Admin',
    updatedAt: poV2.updatedAt,
    companyVatNumber: '',
    additionalCostsArray: poV2.additionalCosts,
    issueDate: poV2.issueDate,
  };
}

export function mapAllInvoicesToV1(invoices: InvoiceV2[]): Invoice[] {
  const allInvoices: Invoice[] = [];
  invoices.forEach((element) => {
    const inv = mapV2InvoiceToV1(element);
    allInvoices.push(inv);
  });
  return allInvoices;
}

export function mapAllInvoicesToV1Service(
  result: InvoiceV2[],
  total: number,
): { result: Invoice[]; total: number } {
  return {
    result: mapAllInvoicesToV1(result),
    total,
  };
}

export function convertAdditionalCostArraytoHashmap(additionalCosts) {
  const map = [];
  additionalCosts?.forEach((cost) => {
    map.push({
      name: cost.costName,
      totalTaxExclusive: cost.costAmount,
      id: cost.id,
      ...(cost.taxType && { taxType: cost.taxType }),
    });
  });
  return map;
}

export function mapTrackItemsV2(
  trackItems: PosInvoiceTypes.VariantToInvoiceTrack[],
  ecard: PosInvoiceTypes.VariantToInvoiceEcard[],
): TrackItem[] {
  const trackItemsOutput: TrackItem[] = [];
  if (trackItems) {
    trackItems.forEach((element) => {
      const item: TrackItem = {
        id: element.id,
        issueDate: element.issueDate,
        expiryDate: element.expiryDate,
        quantity: element.quantity,
        trackNumber: element.trackNo,
      };
      trackItemsOutput.push(item);
    });
  }
  if (ecard) {
    ecard.forEach((element) => {
      const item: TrackItem = {
        id: element.id,
        issueDate: null,
        expiryDate: null,
        quantity: 1,
        trackNumber: element.code,
      };
      trackItemsOutput.push(item);
    });
  }
  return trackItemsOutput;
}

export function mapV1TrackTypeToV2(
  trackType: 'batch' | 'serial',
): TrackTypeV2 | null {
  if (trackType === 'batch') {
    return TrackTypeV2.Batch;
  }
  if (trackType === 'serial') {
    return TrackTypeV2.Serial;
  }
  return null;
}

export function mapStockOrderItemsV2(
  vti: VariantToInvoice[],
): StockOrderItem[] {
  const stockOrderItems: StockOrderItem[] = [];
  vti?.forEach((element) => {
    const item: StockOrderItem = {
      id: element.id,
      rate:
        element.VariantToInvoicePacks.length > 0
          ? element.VariantToInvoicePacks[0].rate
          : 1,
      variantType: element.type,
      productVariantId: element.productVariantId,
      name: element.name,
      sku: element.sku,
      variantId: element.productVariantId,
      requestedQuantity: +element.quantity || 0,
      availableLocationQuantity: element.availableLocationQuantity || 0,
      oldCost: +element.oldCost || 0,
      defaultTaxConfig: element.Tax,
      buyPrice: element.buyPriceExclusive || 0,
      stockOrderTrackedItems:
        element.VariantToInvoiceTracks || element.VariantToInvoiceEcards
          ? mapTrackItemsV2(
              element.VariantToInvoiceTracks,
              element.VariantToInvoiceEcards,
            )
          : [],
      averageCost: element.newCost,
      requisitionCost: +element.costExclusive || 0,
      totalTax: element.taxAmount,
      tax: element.Tax,
      createdAt: element.createdAt,
      updatedAt: element.updatedAt,
      deletedAt: element.deletedAt,
      discountAmount: element.discount,
      receivedQuantity: element.quantity,
      trackType:
        element.ProductVariant.Product.type === 'ecard'
          ? TrackTypeV2.Ecard
          : mapV1TrackTypeToV2(element.trackType),
    };
    stockOrderItems.push(item);
  });
  return stockOrderItems;
}

export function mapDiscountV2(pi: PayableInvoice): {
  amount: number;
  type: 'amount' | 'percentage';
} {
  if (pi.discountType === 'fixed') {
    pi.discountType = 'amount';
  }

  return {
    amount: pi.discountAmount,
    type: pi.discountType as 'amount' | 'percentage',
  };
}

export function mapMetadataV2(
  metadata: Metadata,
  costCenterIds: number[],
): MetadataV2 {
  const meta: MetadataV2 = {
    taxType: metadata.taxType,
    costCenterIds,
    payment: metadata?.payment || null,
  };
  return meta;
}

export function mapPaymentsV2(pi: PayableInvoice): PaymentV2 {
  let payments: PaymentV2 = {};
  if (pi.paymentMethod) {
    payments = {
      paidAmount: pi.paidAmount,
      paymentMethod: {
        name: pi.paymentMethod?.name,
        id: pi.paymentMethod?.id,
        type: pi.paymentMethod?.type,
        bankId: pi.paymentMethod?.bankId ? pi.paymentMethod.bankId : null,
        bankName: pi.paymentMethod?.Bank?.name || null,
        accountNumber: pi.paymentMethod?.Bank?.accountNumber || null,
      },
    };
  } else {
    payments = {
      paidAmount: pi.paidAmount,
    };
  }
  return payments;
}

export function mapPurchaseOrderApiToV2(
  po: PurchaseOrderApiInput,
  defaultTax: Tax,
  additionalCostArray,
  issueDate?: Date,
  oneTimeCode?: string,
): PurchaseOrderApiInputV2 {
  const purchaseOrder: PurchaseOrderApiInputV2 = {
    id: po.id,
    supplierId: po.supplierId,
    supplierName: po.supplierName,
    supplierInvoiceNumber: po.PayableInvoice.supplierInvoiceNumber,
    issueDate,
    stockLocationId: po.stockLocationId,
    stockLocationName: po.stockLocationName,
    status: poInvoiceStatusV1ToV2[po.status],
    notes: po.notes,
    stockOrderItems: mapStockOrderItemsV2(po.VariantToInvoices),
    payment: po.PayableInvoice ? new PaymentV2() : undefined,
    defaultTaxConfig: defaultTax,
  };
  if (po.deliveryDate) {
    purchaseOrder.expectedDeliveryDate = po.deliveryDate;
  }
  if (po.PayableInvoice.paymentDueDate) {
    purchaseOrder.paymentDueDate = po.PayableInvoice.paymentDueDate;
  }
  const additionalCosts =
    convertAdditionalCostArraytoHashmap(additionalCostArray);
  const additionalCostsFilter = additionalCosts.filter(
    (item) => item.totalTaxExclusive !== null,
  );

  if (additionalCostsFilter.length) {
    purchaseOrder.additionalCosts = additionalCostsFilter;
  }
  if (po.PayableInvoice) {
    purchaseOrder.payments = [];
    purchaseOrder.payment = mapPaymentsV2(po.PayableInvoice);
    purchaseOrder.payments.push(purchaseOrder.payment);
  }
  purchaseOrder.metadata = mapMetadataV2(po.metadata, po.costCenterIds);
  if (po.metadata.attachments && po.metadata.attachments.length) {
    purchaseOrder.attachments = po.metadata.attachments;
  }
  if (po.PayableInvoice.discountType) {
    purchaseOrder.discount = mapDiscountV2(po.PayableInvoice);
  }
  if (oneTimeCode) {
    purchaseOrder.oneTimeCode = oneTimeCode;
  }
  return purchaseOrder;
}

export function mapSortByToV2(values: {
  [name: string]: 'ASC' | 'DESC';
}): string {
  let sortBy = '';
  // eslint-disable-next-line guard-for-in
  for (const key in values) {
    switch (key) {
      case 'paymentDueDate':
        sortBy += 'expectedDeliveryDate';
        break;
      case 'invoiceStatus':
        sortBy += 'status';
        break;
      case 'invoiceNumber':
        sortBy += 'id';
        break;
      case 'issueDate':
        sortBy += 'issueDate';
        break;
      case 'supplier':
        sortBy += 'supplierName';
        break;
      case 'StockLocation':
        sortBy += 'stockLocationName';
        break;
      case 'completeDate':
        sortBy += 'completedAt';
        break;
      default:
        sortBy += key;
    }
  }
  return sortBy;
}

export function mapSortDirectionToV2(values: {
  [name: string]: 'ASC' | 'DESC';
}): string {
  let sortDirection = '';
  // eslint-disable-next-line guard-for-in
  for (const key in values) {
    sortDirection += values[key];
  }
  return sortDirection;
}

export const queryOperatorsV2 = {
  '=': 'equal',
  '>': 'moreThan',
  '<': 'lessThan',
  '<=': 'LessThanOrEqual',
  '>=': 'MoreThanOrEqual',
};

export function mapFiltersToV2(params: IInvoiceSearchFilter): Filter[] {
  const filterList: Filter[] = [];
  const columnNames = [
    'invoiceStatus',
    'paymentDueDate',
    'paymentStatus',
    'supplierId',
    'sourceLocationId',
    'invoiceNumberFilter',
    'totalTaxInclusive',
    'issueDate',
    'completeDate',
    'supplierInvoiceNumber',
    'updatedAt',
  ];
  const paymentStatusesV2 = [];
  if (params.paymentStatus && Array.isArray(params.paymentStatus)) {
    params.paymentStatus.forEach((element) => {
      switch (element) {
        case 'Paid':
          paymentStatusesV2.push('paid');
          break;
        case 'PARTIALLY_PAID':
          paymentStatusesV2.push('partially-paid');
          break;
        case 'NOT_PAID':
          paymentStatusesV2.push('not-paid');
          break;
        case 'Draft':
          paymentStatusesV2.push('draft');
          break;
        default:
          break;
      }
    });
  }

  if (params.updatedAtFromDate || params.updatedAtToDate) {
    const updatedAt = {
      fromDate: params.updatedAtFromDate ?? undefined,
      toDate: params.updatedAtToDate ?? undefined,
    };
    params.updatedAt = updatedAt;
  }
  columnNames.forEach((element) => {
    if (element in params) {
      if (!params[element]) {
        return;
      }
      switch (element) {
        case 'paymentStatus':
          if (params[element]) {
            filterList.push({
              columnName: 'paymentStatus',
              value: paymentStatusesV2,
              matchMode: 'in',
            });
          }
          break;
        case 'invoiceStatus':
          if (params[element]) {
            filterList.push({
              columnName: 'status',
              value: params[element],
              matchMode: 'in',
            });
          }
          break;
        case 'supplierId':
          if (params[element]) {
            filterList.push({
              columnName: 'supplierId',
              value: params[element],
              matchMode: 'in',
            });
          }
          break;
        case 'sourceLocationId':
          if (params[element]) {
            filterList.push({
              columnName: 'stockLocationId',
              value: params[element],
              matchMode: 'in',
            });
          }
          break;
        case 'invoiceNumberFilter':
          if (params[element]) {
            filterList.push({
              columnName: 'invoiceNumber',
              value: params[element],
              matchMode: 'like',
            });
          }
          break;
        case 'totalTaxInclusive':
          if (params[element].value) {
            filterList.push({
              columnName: 'totalTaxInclusive',
              value: params[element].value,
              matchMode: queryOperatorsV2[params[element].operator],
            });
          }
          break;
        case 'issueDate':
          if (
            params[element] &&
            (params[element].fromDate || params[element].toDate)
          ) {
            filterList.push({
              columnName: 'issueDate',
              value: [params[element].fromDate, params[element].toDate],
              matchMode: 'between',
            });
          }
          break;
        case 'completeDate':
          if (
            params[element] &&
            (params[element].fromDate || params[element].toDate)
          ) {
            filterList.push({
              columnName: 'completedAt',
              value: [params[element].fromDate, params[element].toDate],
              matchMode: 'between',
            });
          }
          break;
        case 'updatedAt':
          if (
            params[element] &&
            (params[element].fromDate || params[element].toDate)
          ) {
            filterList.push({
              columnName: 'updatedAt',
              value: [params[element].fromDate, params[element].toDate],
              matchMode: 'between',
            });
          }
          break;
        case 'paymentDueDate':
          if (params[element].fromDate) {
            filterList.push({
              columnName: 'expectedDeliveryDate',
              value: [params[element].fromDate, params[element].toDate],
              matchMode: 'between',
            });
          }
          break;
        case 'supplierInvoiceNumber':
          if (params[element]) {
            filterList.push({
              columnName: 'supplierInvoiceNumber',
              value: params[element],
              matchMode: 'like',
            });
          }
          break;
        default:
          break;
      }
    }
  });
  return filterList;
}

export function mapQueryParamsToV2(
  params: IInvoiceSearchFilter,
): IInvoiceSearchFilterV2 {
  const query: IInvoiceSearchFilterV2 = {
    sortBy: mapSortByToV2(params.sortBy),
    sortDirection: mapSortDirectionToV2(params.sortBy),
    limit: params.limit,
    offset: params.offset,
    filters: JSON.stringify(mapFiltersToV2(params)),
  };
  if (params.search) {
    query.search = params.search;
  }
  return query;
}

export function mapGetInvoicesParamsToV2(
  supplierId: number,
  type: string,
  paymentStatuses: string[],
  invoiceNumber?: string,
): IInvoiceSearchFilterV2 {
  const paymentStatusesV2 = [];
  paymentStatuses.forEach((element) => {
    paymentStatusesV2.push(poPaymentStatusV1toV2[element]);
  });
  const filtersArr: Filter[] = [
    {
      columnName: 'supplierId',
      value: supplierId,
      matchMode: 'equal',
    },
    {
      columnName: 'paymentStatus',
      value: paymentStatusesV2,
      matchMode: 'in',
    },
  ];
  if (invoiceNumber) {
    filtersArr.push({
      columnName: 'invoiceNumber',
      value: invoiceNumber,
      matchMode: 'equal',
    });
  }
  return {
    filters: JSON.stringify(filtersArr),
    sortBy: 'supplierId',
    sortDirection: 'asc',
  };
}

export function mapV1PayCreditToV2(
  payV1: PayCreditRequest,
): PayCreditRequestV2 {
  return {
    supplierId: payV1.supplierId,
    supplierName: payV1.supplierName,
    paymentMethod: payV1.paymentMethod,
    note: payV1.note,
    payment: {
      paymentMethod: {
        name: payV1.paymentMethod?.name,
        id: payV1.paymentMethod?.id,
        type: payV1.paymentMethod?.type,
        bankId: payV1.paymentMethod?.bankId ? payV1.paymentMethod.bankId : null,
        bankName: payV1.paymentMethod?.Bank?.name || null,
        accountNumber: payV1.paymentMethod?.Bank?.accountNumber || null,
      },
      paidAmount: payV1.totalPaid,
    },
    invoiceDetails: payV1.invoicesDetails,
    paidAmount: payV1.totalPaid,
    totalBeforePayment: payV1.totalCreditBeforePayment,
    uninvoicedPaidAmount: payV1.uninvoicedPaidAmount,
    oneTimeCode: payV1.oneTimeCode,
  };
}

export function mapReturnInvoiceV2ToV1(result: any[]): Invoice[] {
  const invoices: Invoice[] = [];
  result.forEach((rtn) => {
    invoices.push({
      totalPaidAmount: rtn.paidAmount,
      PayableInvoice: {
        paymentDueDate: rtn.paymentDueDate,
        paymentMethod: '',
        paymentStatus: poPaymentStatusV2toV1[rtn.paymentStatus],
        supplierInvoiceNumber: rtn.supplierInvoiceNumber,
        paidAmount: rtn.paidAmount,
        debitAmount: rtn.remainingBalance,
        invoiceId: rtn.id,
      },
      Supplier: {
        email: rtn.supplier.email,
        phoneNumber: rtn.supplier.phoneNumber,
        streetAddress: rtn.supplier.streetAddress,
        name: rtn.supplier.name,
        suburb: rtn.supplier.suburb,
        vatNumber: rtn.supplier.vatNumber,
        city: rtn.supplier.city,
        countryKey: rtn.supplier.countryKey,
        code: rtn.supplier.code,
        buildingNumber: rtn.supplier.buildingNumber,
        district: rtn.supplier.district,
        zipCode: rtn.supplier.zipCode,
        additionalNumber: rtn.supplier.additionalNumber,
        debitAmount: rtn.supplier.debitAmount,
        creditAmount: rtn.supplier.creditAmount,
        totalPaidAmount: rtn.supplier.totalPaidAmount,
        id: rtn.supplier.id,
        state: rtn.supplier.state,
        createdAt: rtn.supplier.createdAt,
        label: '',
        value: 0,
      },
      invoiceNumber: rtn.invoiceNumber,
      status: poInvoiceStatusV2ToV1[rtn.status],
      notes: rtn.notes,
      type: typeV2ToV1[rtn.orderType],
      subTotalTaxExclusive: rtn.subTotalTaxExclusive,
      totalTax: rtn.totalTax,
      totalTaxInclusive: rtn.totalTaxInclusive,
      stockLocationName: rtn.stockLocationName,
      supplierName: rtn.supplier.name,
      userId: rtn.createdBy,
      id: rtn.id,
      stockLocationId: rtn.stockLocationId,
      supplierId: rtn.supplier.id,
      createdAt: rtn.createdAt,
      updatedAt: rtn.updatedAt,
    });
  });
  return invoices;
}

export function mapPayPoV2ToV1(response: PayPoResponseV2): Invoice {
  return {
    PayableInvoice: {
      paymentMethod: response.paymentLines[0].paymentMethodName,
    },
    createdAt: new Date(response.createdAt),
    invoiceNumber: response.paymentInvoiceNumber,
    completeDate: new Date(
      response.paymentLines[0].metadata.snapshot.stockOrder.expectedDeliveryDate,
    ),
    status: 'Completed',
    notes: response.paymentLines[0].metadata.snapshot.stockOrder.notes,
    type: 'PayPurchaseOrder',
    supplierName: response.supplierName,
    poInvoice: {
      invoiceNumber:
        response.paymentLines[0].metadata.snapshot.stockOrder.invoiceNumber,
      completeDate: new Date(),
      type: 'PurchaseOrder',
      subTotalTaxExclusive:
        response.paymentLines[0].metadata.snapshot.stockOrder
          .subTotalTaxExclusive,
      totalTax: response.paymentLines[0].metadata.snapshot.stockOrder.totalTax,
      totalTaxInclusive:
        response.paymentLines[0].metadata.snapshot.stockOrder.totalTaxInclusive,
      PayableInvoice: {
        paymentStatus:
          poPaymentStatusV2toV1[
            response.paymentLines[0].metadata.snapshot.stockOrder.paymentStatus
          ],
        paidAmount: response.paymentLines[0].amount,
        debitAmount:
          response.paymentLines[0].metadata.snapshot.stockOrder
            .totalTaxInclusive - response.paymentLines[0].amount,
      },
    },
    issueDate: new Date(response.issueDate),
  };
}

export function mapPayCreditV2ToV1(response: PayPoResponseV2): Invoice {
  const childPurchaseOrderInvoices = [];
  const childReturnInvoices = [];
  response.paymentLines.forEach((p) => {
    if (p.adjustedOrderId && p.metadata.snapshot.adjustedOrder) {
      const { adjustedOrder } = p.metadata.snapshot;
      childReturnInvoices.push({
        invoiceNumber: adjustedOrder.invoiceNumber,
        completeDate: p.createdAt,
        status: 'Completed',
        subTotalTaxExclusive: adjustedOrder.subTotalTaxExclusive,
        totalTax: adjustedOrder.totalTax,
        totalTaxInclusive: adjustedOrder.totalTaxInclusive,
        type: 'RejectStock',
        supplierName: response.supplierName,
        totalPaidAmount: adjustedOrder.totalPaidBeforePayment,
        PayableInvoice: {
          paymentDueDate: p.updatedAt,
          paymentStatus: poPaymentStatusV2toV1[adjustedOrder.paymentStatus],
          paidAmount: p.amount,
          debitAmount: adjustedOrder.remainingAmountBeforePayment,
          PaymentToPayableInvoices: {
            returnInvoicePaidAmount: p.amount,
            Payment: {
              paymentMethod: p.paymentMethodName,
              paidAmount: p.amount,
            },
          },
        },
      });
    }
    if (p.metadata.snapshot.stockOrder) {
      const { stockOrder } = p.metadata.snapshot;
      childPurchaseOrderInvoices.push({
        invoiceNumber: stockOrder.invoiceNumber,
        status: 'Completed',
        totalTaxInclusive: stockOrder.totalTaxInclusive,
        subTotalTaxExclusive: stockOrder.subTotalTaxExclusive,
        totalTax: stockOrder.totalTax,
        supplierName: response.supplierName,
        deliveryDate: stockOrder.expectedDeliveryDate,
        totalPaidAmount: stockOrder.totalPaidBeforePayment,
        PayableInvoice: {
          paidAmount: stockOrder.amount ? stockOrder.amount : p.amount,
          paymentStatus:
            poPaymentStatusV2toV1[stockOrder.paymentStatusBeforePayment],
          debitAmount: stockOrder.remainingAmountBeforePayment,
          PaymentToPayableInvoices: {
            Payment: {
              paidAmount: stockOrder.amount ? stockOrder.amount : p.amount,
            },
          },
        },
      });
    }
  });
  const totalBeforePayment = response.paymentLines.reduce((acc, obj) => {
    if (
      obj.metadata?.snapshot?.stockOrder?.remainingAmountBeforePayment !==
      undefined
    ) {
      return (
        acc + obj.metadata?.snapshot?.stockOrder?.remainingAmountBeforePayment
      );
    }
    return acc;
  }, 0);
  return {
    type: 'PayCredit',
    supplierName: response.supplierName,
    invoiceNumber: response.paymentInvoiceNumber,
    createdAt: new Date(response.createdAt),
    status: 'Completed',
    totalPaidAmount: response.amount,
    PayableInvoice: {
      paymentMethod: response.paymentLines[0].paymentMethodName,
      totalBeforePayment,
      totalAfterPayment: totalBeforePayment - response.amount,
    },
    childPurchaseOrderInvoices,
    childReturnInvoices,
    issueDate: response.issueDate,
  };
}
