import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { PAYMENT_STATUSES } from '../../../shared/constants';
import { AmountChangedErrorMessages } from '../utils/constants';
import { mapInvoiceToDebitable } from '../utils/mapper';
import {
  PayCreditRequest,
  PayCreditRequestV2,
} from '../model/pay-credit-request';
import { ReceiveDebitRequest } from '../model/receive-debit-request';
import { InvoicesCalculator } from '../../utilities/invoices.calculator';
import { mapV1PayCreditToV2 } from '../../services/mapper-functions-v2';
import { FeatureFlagEnum } from '../../../shared/constants/feature-flag.constants';
import { FeatureFlagService } from '../../../shared/services/types/feature-flag.service.interface';

const API_URL = '/api';

@Injectable()
export class PaymentService {
  constructor(
    private readonly http: HttpClient,
    private readonly featureFlagService: FeatureFlagService,
  ) {}

  handlePOAmountsChanged(
    amount,
    invoiceId,
    enteredAmounts,
    mainInvoices,
    mainInvoicesData,
    debitSelected = false,
    returnInvoicesData,
  ) {
    // valid amount = less than or equal invoice credit + if debit selected, less than or equal total of return invoices
    const selectedMainInvoice = mainInvoices.find(
      (mainInvoice) => mainInvoice.id === invoiceId,
    );
    const selectedMainInvoiceData = mainInvoicesData.find(
      (mainInvoice) => mainInvoice.id === invoiceId,
    );

    if (
      amount >
      InvoicesCalculator.calculateCredit(
        selectedMainInvoice.totalTaxInclusive,
        selectedMainInvoice.totalPaidAmount,
      )
    ) {
      return this.updatePaymentDataForInvalidAmount(
        enteredAmounts,
        selectedMainInvoice,
        selectedMainInvoiceData,
        invoiceId,
        AmountChangedErrorMessages.AMOUNT_MUST_NOT_EXCEED_INVOICE_CREDIT,
      );
    }

    if (amount <= 0) {
      return this.updatePaymentDataForInvalidAmount(
        enteredAmounts,
        selectedMainInvoice,
        selectedMainInvoiceData,
        invoiceId,
        undefined,
      );
    }

    const foundEnteredAmount = enteredAmounts.find(
      (enteredAmount) => enteredAmount.id === invoiceId,
    );
    if (foundEnteredAmount) {
      foundEnteredAmount.amount = amount;
    } else {
      enteredAmounts.push({ id: invoiceId, amount });
    }
    if (debitSelected) {
      const totalDebit = returnInvoicesData.reduce(
        (previousValue, returnInvoiceData) =>
          InvoicesCalculator.add(previousValue, returnInvoiceData.debit),
        0,
      );
      const totalEnteredAmounts = enteredAmounts.reduce(
        (previousValue, enteredAmount) =>
          InvoicesCalculator.add(previousValue, enteredAmount.amount),
        0,
      );
      if (totalEnteredAmounts > totalDebit) {
        return this.updatePaymentDataForInvalidAmount(
          enteredAmounts,
          selectedMainInvoice,
          selectedMainInvoiceData,
          invoiceId,
          AmountChangedErrorMessages.TOTAL_AMOUNTS_MUST_NOT_EXCEED_TOTAL_DEBIT,
        );
      }
    }

    // update selected po table record with valid amount
    selectedMainInvoiceData.totalPaidAfterPayment =
      InvoicesCalculator.calculateTotalPaidAfterPayment(
        selectedMainInvoice.totalPaidAmount,
        amount,
      );
    selectedMainInvoiceData.creditAfterPayment =
      InvoicesCalculator.calculateCreditAfterPayment(
        selectedMainInvoice.totalTaxInclusive,
        selectedMainInvoice.totalPaidAmount,
        amount,
      );

    // handle status after payment
    selectedMainInvoiceData.statusAfterPayment =
      this.handleStatusAfterPaymentForPO(amount, selectedMainInvoice);

    return { enteredAmounts };
  }

  private updatePaymentDataForInvalidAmount(
    enteredAmounts,
    selectedMainInvoice,
    selectedMainInvoiceData,
    invoiceId,
    errorMessage,
    mode = 'PAY_CREDIT',
  ) {
    enteredAmounts = enteredAmounts.filter(
      (enteredAmount) => enteredAmount.id !== invoiceId,
    );
    selectedMainInvoiceData.statusAfterPayment =
      selectedMainInvoice.PayableInvoice.paymentStatus;
    selectedMainInvoiceData.totalPaidAfterPayment =
      selectedMainInvoice.totalPaidAmount;
    if (mode === 'RECEIVE_DEBIT') {
      selectedMainInvoiceData.debitAfterPayment =
        InvoicesCalculator.calculateDebit(
          selectedMainInvoice.totalTaxInclusive,
          selectedMainInvoice.totalPaidAmount,
        );
    } else if (mode === 'PAY_CREDIT') {
      selectedMainInvoiceData.creditAfterPayment =
        InvoicesCalculator.calculateCredit(
          selectedMainInvoice.totalTaxInclusive,
          selectedMainInvoice.totalPaidAmount,
        );
    }

    return errorMessage ? { errorMessage, enteredAmounts } : { enteredAmounts };
  }

  handleRtnAmountsChanged(
    newEnteredAmounts,
    returnInvoices,
    returnInvoicesData,
  ) {
    const totalEnteredAmounts = newEnteredAmounts.reduce(
      (previousValue, enteredAmount) =>
        InvoicesCalculator.add(previousValue, enteredAmount.amount),
      0,
    );

    // reset
    returnInvoicesData.splice(0, returnInvoicesData.length);
    returnInvoices
      .map((invoice) => mapInvoiceToDebitable(invoice))
      .forEach((invoiceData) => returnInvoicesData.push(invoiceData));

    let remainingTotalEnteredAmounts = totalEnteredAmounts;
    while (remainingTotalEnteredAmounts > 0) {
      for (const returnInvoiceData of returnInvoicesData) {
        const deductedAmount = InvoicesCalculator.subtract(
          remainingTotalEnteredAmounts,
          returnInvoiceData.debit,
        );
        if (deductedAmount >= 0) {
          returnInvoiceData.statusAfterPayment = PAYMENT_STATUSES.PAID;
          returnInvoiceData.amount = returnInvoiceData.debit;
          returnInvoiceData.totalPaidAfterPayment = returnInvoiceData.debit;
          returnInvoiceData.debitAfterPayment = 0;
          remainingTotalEnteredAmounts = deductedAmount;
        } else if (deductedAmount < 0) {
          returnInvoiceData.statusAfterPayment =
            PAYMENT_STATUSES.PARTIALLY_PAID_DEBTOR;
          returnInvoiceData.amount = remainingTotalEnteredAmounts;
          returnInvoiceData.totalPaidAfterPayment =
            InvoicesCalculator.calculateTotalPaidAfterPayment(
              returnInvoiceData.totalPaid,
              remainingTotalEnteredAmounts,
            );
          returnInvoiceData.debitAfterPayment =
            InvoicesCalculator.calculateDebitAfterPayment(
              returnInvoiceData.total,
              returnInvoiceData.totalPaid,
              remainingTotalEnteredAmounts,
            );
          remainingTotalEnteredAmounts = deductedAmount;
          break;
        }
      }
    }
  }

  payCreditV2(payCreditRequest: PayCreditRequest): Observable<boolean> {
    const payCreditRequestV2 = mapV1PayCreditToV2(payCreditRequest);
    if (
      payCreditRequestV2.invoiceDetails.length === 1 &&
      payCreditRequestV2.uninvoicedPaidAmount <= 0
    ) {
      return this.payCreditSingleV2(payCreditRequestV2);
    }
    return this.http.post<boolean>(
      `${API_URL}/stock-control/purchase-order/pay-credit`,
      payCreditRequestV2,
    );
  }

  payCreditSingleV2(
    payCreditRequestV2: PayCreditRequestV2,
  ): Observable<boolean> {
    const payload = {
      supplierId: payCreditRequestV2.supplierId,
      supplierName: payCreditRequestV2.supplierName,
      payment: payCreditRequestV2.payment,
      note: payCreditRequestV2.note,
      invoiceDetails: payCreditRequestV2.invoiceDetails[0],
      totalBeforePayment: payCreditRequestV2.totalBeforePayment,
      oneTimeCode: payCreditRequestV2.oneTimeCode,
    };
    return this.http.post<boolean>(
      `${API_URL}/stock-control/purchase-order/pay-credit/${payload.invoiceDetails.id}`,
      payload,
    );
  }

  payCredit(payCreditRequest: PayCreditRequest) {
    return this.http.post<any>(
      `${API_URL}/payments/pay-credit`,
      payCreditRequest,
    );
  }

  handleRtnAmountsChangedForReceiveDebit(
    amount,
    invoiceId,
    enteredAmounts,
    mainInvoices,
    mainInvoicesData,
  ) {
    // valid amount = less than or equal invoice credit + if debit selected, less than or equal total of return invoices
    const selectedMainInvoice = mainInvoices.find(
      (mainInvoice) => mainInvoice.id === invoiceId,
    );
    const selectedMainInvoiceData = mainInvoicesData.find(
      (mainInvoice) => mainInvoice.id === invoiceId,
    );

    if (
      amount >
      InvoicesCalculator.subtract(
        selectedMainInvoice.totalTaxInclusive,
        selectedMainInvoice.totalPaidAmount,
      )
    ) {
      return this.updatePaymentDataForInvalidAmount(
        enteredAmounts,
        selectedMainInvoice,
        selectedMainInvoiceData,
        invoiceId,
        AmountChangedErrorMessages.AMOUNT_MUST_NOT_EXCEED_INVOICE_DEBIT,
        'RECEIVE_DEBIT',
      );
    }

    if (amount <= 0) {
      return this.updatePaymentDataForInvalidAmount(
        enteredAmounts,
        selectedMainInvoice,
        selectedMainInvoiceData,
        invoiceId,
        undefined,
        'RECEIVE_DEBIT',
      );
    }

    const foundEnteredAmount = enteredAmounts.find(
      (enteredAmount) => enteredAmount.id === invoiceId,
    );
    if (foundEnteredAmount) {
      foundEnteredAmount.amount = amount;
    } else {
      enteredAmounts.push({ id: invoiceId, amount });
    }

    // update selected po table record with valid amount
    selectedMainInvoiceData.totalPaidAfterPayment = InvoicesCalculator.add(
      selectedMainInvoice.totalPaidAmount,
      amount,
    );
    selectedMainInvoiceData.debitAfterPayment =
      InvoicesCalculator.calculateDebitAfterPayment(
        selectedMainInvoice.totalTaxInclusive,
        selectedMainInvoice.totalPaidAmount,
        amount,
      );

    // handle status after payment
    selectedMainInvoiceData.statusAfterPayment =
      this.handleStatusAfterPaymentForRtn(amount, selectedMainInvoice);

    return { enteredAmounts };
  }

  handleStatusAfterPaymentForRtn(amount, selectedMainInvoice) {
    const debit = InvoicesCalculator.calculateDebit(
      selectedMainInvoice.totalTaxInclusive,
      selectedMainInvoice.totalPaidAmount,
    );
    if (amount < debit) {
      return PAYMENT_STATUSES.PARTIALLY_PAID_DEBTOR;
    }
    if (amount === debit) {
      return PAYMENT_STATUSES.PAID;
    }
  }

  receiveDebit(receiveDebitRequest: ReceiveDebitRequest) {
    return this.http.post<any>(
      `${API_URL}/payments/receive-debit`,
      receiveDebitRequest,
    );
  }

  handleStatusAfterPaymentForPO(amount: number, selectedMainInvoice) {
    const credit = InvoicesCalculator.calculateCredit(
      selectedMainInvoice.totalTaxInclusive,
      selectedMainInvoice.totalPaidAmount,
    );
    if (amount < credit) {
      return PAYMENT_STATUSES.PARTIALLY_PAID_CREDITOR;
    }
    if (amount === credit) {
      return PAYMENT_STATUSES.PAID;
    }
  }

  getLastPaymentNumber() {
    return this.http.get<any>(`${API_URL}/payments/last-number`);
  }

  getPaymentsList(filtersData, search?: string): Observable<any> {
    let sortByKey = null;
    let sortByValue = null;
    if (filtersData?.sortBy) {
      [sortByKey] = Object.keys(filtersData?.sortBy);
      sortByValue = filtersData?.sortBy[sortByKey];
    }
    let params;
    params = new HttpParams()
      .set('offset', filtersData.offset || '0')
      .set('limit', filtersData.limit || '10')
      .set('search', search ?? '')
      .set(
        'filters',
        filtersData?.filters?.length > 0
          ? JSON.stringify(filtersData.filters)
          : '',
      );
    params = params.set('sortBy', 'id').set('sortDirection', 'desc');
    return this.http.get<any>(`${API_URL}/stock-control/payments`, {
      params,
    });
  }

  gePaymentByInvoiceNo(invoiceNo: string) {
    return this.http.get<any>(`${API_URL}/stock-control/payments/${invoiceNo}`);
  }

  async getFeatureFlagPO(): Promise<boolean> {
    return firstValueFrom(
      this.featureFlagService.isEnabled(
        FeatureFlagEnum.PurchaseOrderRevampV2,
        false,
      ),
    );
  }

  mappedPaymentToOld(invoiceData) {
    return {
      rtnInvoice: {
        PayableInvoice: {
          paymentMethod: invoiceData.payments.paymentLines[0].paymentMethodName,
          paymentMethodId: invoiceData.payments.paymentLines[0].paymentMethodId,
          paidAmount: invoiceData.stockOrders[0].paidAmount,
          debitAmount: invoiceData.stockOrders[0].remainingBalance,
          paymentStatus: this.capitalizePaymentStatus(
            invoiceData.stockOrders[0].paymentStatus,
          ),
        },
        invoiceNumber: invoiceData.stockOrders[0].invoiceNumber,
        totalTaxInclusive: invoiceData.stockOrders[0].totalTaxInclusive,
      },
      PayableInvoice: {
        paymentMethod: invoiceData.payments.paymentLines[0].paymentMethodName,
      },
      status:
        invoiceData.stockOrders[0].status.charAt(0).toUpperCase() +
        invoiceData.stockOrders[0].status.slice(1),
      totalTaxInclusive: invoiceData.stockOrders[0].totalTaxInclusive,
      createdAt: invoiceData.stockOrders[0].createdAt,
      invoiceNumber: invoiceData.payments.invoiceNumber,
      notes: invoiceData.payments.notes,
      supplierName: invoiceData.payments.suppliers.name,
      issueDate: invoiceData.payments.issueDate,
    };
  }

  capitalizePaymentStatus(paymentStatus: string): string {
    return paymentStatus
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
}
